.amenities-container {
    padding: 50px 20px;
    background-color: #f9f9f9;
  }
  
  .amenities-title {
    text-align: center;
    font-size: 30px;
    color: #333;
    margin-bottom: 40px;
    text-transform: uppercase;
    line-height: 50px;
  }
  
  .amenities-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .amenity-card {
    background-color:var(--brown);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .amenity-card:hover {
    transform: translateY(-10px);
  }
  
  .amenity-icon {
    font-size: 40px;
    color:var(--);
    margin-bottom: 20px;
  }
  
  .amenity-name {
    font-size: 24px;
    font-weight: bold;
    color: var(--green);
    margin-bottom: 10px;
  }
  
  .amenity-description {
    font-size: 16px;
    color:#fff;
  }
  

  .amenity-icon{
    color:var(--green);
  }