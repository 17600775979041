.booking-form-container {
    max-width: 800px;
    margin: 30px auto;
    background-color: #f8f9fa;
    padding:30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border:2px solid var(--brown);
   
  }
  
  .bookHead{
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #333;
  }
  
  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--green);
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  textarea {
    height: 100px;
    resize: vertical;
  }
  
  .btn-submit {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-submit:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .booking-form-container {
      padding: 20px;
    }
  
    h2 {
      font-size: 24px;
    }
  
    .btn-submit {
      padding: 10px;
      font-size: 14px;
    }
  }
  