/*==========================================================================
Common Css
==========================================================================*/
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&amp;display=swap");


 
body {
  font-family: var(--body-font);
  color: var(--body-color);
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
}


img {
  max-width: 100%;
  height: auto;
  transition: all 0.4s ease-out 0s;
}

a {
  outline: none;
  color: inherit;
  text-decoration: none;
}

a,
button,
i {
  text-decoration: none;
  color: inherit;
}

a:focus,
a:hover {
  text-decoration: none;
  color: inherit;
}

.section-padding {
  padding: 70px 0px;
}

@media (max-width: 575px) {
  .section-padding {
    padding: 95px 0px;
  }
}
.display-none {
  display: none;
}

.img__full {
  width: 100%;
}


.services__two-item-icon i{
  /* border:2px solid green; */
  font-size: 3rem;
  color:var(--brown);
}

/*==========================================================================
Form
==========================================================================*/
button,
input[type=button],
input[type=reset],
input[type=submit] {
  border: 1px solid var(--border-color-2);
  border-color: transparent;
  border-radius: 0px;
  background: var(--green);
  color: var(--white);
  padding: 16px 43px;
}

button:hover,
input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
  border-color: transparent;
}

button:active,
button:focus,
input[type=button]:active,
input[type=button]:focus,
input[type=reset]:active,
input[type=reset]:focus,
input[type=submit]:active,
input[type=submit]:focus {
  border-color: transparent;
}

input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color],
textarea {
  color: var(--body-color);
  border: 1px solid;
  border-radius: 0px;
  width: 100%;
  height: 60px;
  border: 1px solid var(--green);
  padding-left: 20px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=number]:focus,
input[type=tel]:focus,
input[type=range]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=time]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=color]:focus,
textarea:focus {
  color: var(--heading-color);
  outline: none;
  box-shadow: none;
  border-color: var(--green);
}

select {
  border: 1px solid var(--border-color-2);
}

textarea {
  width: 100%;
  height: 150px;
  padding-top: 15px;
}

button,
button:hover,
button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

/*==========================================================================
Hedging CSS
==========================================================================*/
h1 {
  font-size: 80px;
  line-height: 90px;
  padding: 0;
  margin: 0;
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-weight: 700;
}

h2 {
  font-size: 45px;
  line-height: 60px;
  padding: 0;
  margin: 0;
  /* color: var(--heading-color); */
  color:var(--green);
  font-family: var(--heading-font);
  font-weight: 500;
}

h3 {
  font-size: 30px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-weight: 500;
}

h4 {
  font-size: 24px;
  line-height: 34px;
  padding: 0;
  margin: 0;
  color: #000;
  font-family: var(--heading-font);
  font-weight: 500;
}

h5 {
  font-size: 20px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-weight: 500;
}

h6 {
  font-size: 18px;
  line-height: 28px;
  padding: 0;
  margin: 0;
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-weight: 500;
}

.t-left {
  text-align: left;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

@media (max-width: 1199px) {
  h2 {
    font-size: 40px;
    line-height: 54px;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 34px;
    line-height: 44px;
  }
}
@media (max-width: 440px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  h3 {
    font-size: 26px;
    line-height: 36px;
  }
  h4 {
    font-size: 22px;
    line-height: 32px;
  }
}
/*==========================================================================
Theme Btn CSS
==========================================================================*/
.theme-btn,
.theme-border-btn {
  background: var(--green);
  color: var(--white);
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  font-weight: 700;
  font-family: var(--heading-font);
  z-index: 3;
  position: relative;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  overflow: hidden;
}
.theme-btn::before,
.theme-border-btn::before {
  position: absolute;
  content: "";
  position: absolute;
  transition: all 0.5s ease-out 0s;
  top: 0;
  left: 0;
  background: var(--heading-color);
  width: 0;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.theme-btn::after,
.theme-border-btn::after {
  position: absolute;
  content: "";
  position: absolute;
  transition: all 0.5s ease-out 0s;
  bottom: 0;
  right: 0;
  background: var(--heading-color);
  width: 0;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.theme-btn i,
.theme-border-btn i {
  margin-left: 14px;
  font-size: 18px;
  line-height: 28px;
  position: relative;
}
.theme-btn:hover,
.theme-border-btn:hover {
  color: var(--white);
}
.theme-btn:hover::before,
.theme-border-btn:hover::before {
  width: 50%;
  opacity: 1;
}
.theme-btn:hover::after,
.theme-border-btn:hover::after {
  width: 50%;
  opacity: 1;
}
.theme-btn:focus,
.theme-border-btn:focus {
  color: var(--white);
}

.theme-border-btn {
  color: var(--green);
  background: transparent;
  border: 1px solid var(--green);
  padding: 15px 42px;
}
.theme-border-btn::before {
  background: var(--green);
}
.theme-border-btn::after {
  background: var(--green);
}
.theme-border-btn:hover {
  color: var(--white);
  border-color: var(--green);
}
.theme-border-btn:focus {
  color: var(--green);
}

.simple-btn {
  display: inline-block;
  color: var(--heading-color);
  font-weight: 600;
  font-family: var(--heading-font);
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}
.simple-btn i {
  display: inline-block;
  margin-right: 7px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--green);
  color: var(--green);
}
.simple-btn i::before {
  font-size: 10px;
}
.simple-btn:hover {
  color: var(--green);
}

.simple-btn-2 {
  display: inline-block;
  color: var(--heading-color);
  font-family: var(--heading-font);
  transition: all 0.4s ease-in-out;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
}
.simple-btn-2 i {
  margin-left: 7px;
}
.simple-btn-2:hover {
  color: var(--green);
}

.subtitle__one {
  display: inline-block;
  position: relative;
  font-weight: 600;
  color: var(--green);
  text-transform: capitalize;
  margin-bottom: 5px;
  font-family: var(--heading-font);
}

@keyframes rotate {
  100% {
    transform: rotate(90deg);
  }
}
/*==========================================================================
Scroll Top CSS
==========================================================================*/
.scroll-up {
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--color-3);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 50px;
  width: 50px;
  transition: all 200ms linear;
}
.scroll-up::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: "\f176";
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  color: var(--green);
  left: 0;
  top: 0;
  font-size: 20px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}
.scroll-up.active-scroll {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.scroll-up svg path {
  fill: none;
}
.scroll-up svg.scroll-circle path {
  stroke: var(--green);
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}

/*==========================================================================
Preloader CSS
========================================================================== */
.spinner {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--white);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.theme-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--heading-color);
  z-index: 9999999999;
}

.theme-loader .spinner {
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
}

/*==========================================================================
Video Animation CSS
==========================================================================*/
.video__play a {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  border: 1px solid var(--color-9);
  color: var(--white);
  text-align: center;
  position: relative;
}
.video__play a i {
  font-size: 20px;
  transition: all 0.4s ease-out 0s;
}
.video__play a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-top: 1.7px solid var(--white);
  border-radius: 50%;
  top: 0;
  left: 0;
  transition: all 0.4s ease-out 0s;
  animation: rotation 2s infinite linear;
  z-index: 2;
}
.video__play a:hover::after {
  border-color: var(--green);
}
.video__play a:hover i {
  color: var(--green);
}

.content__roll {
  animation: roll 10s infinite linear;
}

@keyframes roll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

:root {
  --body-font: "Lato", sans-serif;
  --heading-font: "Bai Jamjuree", sans-serif;
  --white: #FFFFFF;
  --black: #000000;
  --green:#117193;
 --lightbrown:#f6c727;
  --heading-color: #0E1317;
  --body-color: #333334;
  --color-1: #666667;
  --color-2: #999999;
  --color-3: #CCCCCC;
  --color-4: #dddada;
  --color-5: #EDECE7;
  --color-6: #141C21;
  --color-7: #262B2E;
  --color-8: #3E4245;
  --color-9: #8A8A8A;
  --color-10: #D9D9D9;
  --color-11: #E0E0E1;
  --color-12: #CFD0D1;
  --border-color-1: #373939;
  --border-color-2: #E7E7E8;
  --border-color-3: #E7E8E9;
  --box-shadow-1: 0px 10px 50px rgba(0, 0, 0, 0.06);
  --box-shadow-2: 0px 10px 50px rgba(0, 0, 0, 0.05);
  --color-13: #0E1111;
  --border-color-55: #DEDFDF;
  --border-color-5: #282A2A;
  --box-shadow-3: 0px 7px 50px rgba(0, 0, 0, 0.07);
  --box-shadow-4: 0px 10px 50px rgba(0, 0, 0, 0.05);
}

.custom__container {
  max-width: 1600px;
  margin: 0 auto;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mb-105 {
  margin-bottom: 105px;
}

.ml-105 {
  margin-left: 105px;
}

.mr-105 {
  margin-right: 105px;
}

.pt-105 {
  padding-top: 105px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pl-105 {
  padding-left: 105px;
}

.pr-105 {
  padding-right: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.ml-110 {
  margin-left: 110px;
}

.mr-110 {
  margin-right: 110px;
}

.pt-110 {
  padding-top: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pl-110 {
  padding-left: 110px;
}

.pr-110 {
  padding-right: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mb-115 {
  margin-bottom: 115px;
}

.ml-115 {
  margin-left: 115px;
}

.mr-115 {
  margin-right: 115px;
}

.pt-115 {
  padding-top: 115px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pl-115 {
  padding-left: 115px;
}

.pr-115 {
  padding-right: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.ml-120 {
  margin-left: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.pr-120 {
  padding-right: 120px;
}

@media (max-width: 1199px) {
  .xl-pb-0 {
    padding-bottom: 0px;
  }
  .xl-pl-0 {
    padding-left: 0px;
  }
  .xl-pr-0 {
    padding-right: 0px;
  }
  .xl-mb-0 {
    margin-bottom: 0px;
  }
  .xl-ml-0 {
    margin-left: 0px;
  }
  .xl-mr-0 {
    margin-right: 0px;
  }
  .xl-pb-5 {
    padding-bottom: 5px;
  }
  .xl-pl-5 {
    padding-left: 5px;
  }
  .xl-pr-5 {
    padding-right: 5px;
  }
  .xl-mb-5 {
    margin-bottom: 5px;
  }
  .xl-ml-5 {
    margin-left: 5px;
  }
  .xl-mr-5 {
    margin-right: 5px;
  }
  .xl-pb-10 {
    padding-bottom: 10px;
  }
  .xl-pl-10 {
    padding-left: 10px;
  }
  .xl-pr-10 {
    padding-right: 10px;
  }
  .xl-mb-10 {
    margin-bottom: 10px;
  }
  .xl-ml-10 {
    margin-left: 10px;
  }
  .xl-mr-10 {
    margin-right: 10px;
  }
  .xl-pb-15 {
    padding-bottom: 15px;
  }
  .xl-pl-15 {
    padding-left: 15px;
  }
  .xl-pr-15 {
    padding-right: 15px;
  }
  .xl-mb-15 {
    margin-bottom: 15px;
  }
  .xl-ml-15 {
    margin-left: 15px;
  }
  .xl-mr-15 {
    margin-right: 15px;
  }
  .xl-pb-20 {
    padding-bottom: 20px;
  }
  .xl-pl-20 {
    padding-left: 20px;
  }
  .xl-pr-20 {
    padding-right: 20px;
  }
  .xl-mb-20 {
    margin-bottom: 20px;
  }
  .xl-ml-20 {
    margin-left: 20px;
  }
  .xl-mr-20 {
    margin-right: 20px;
  }
  .xl-pb-25 {
    padding-bottom: 25px;
  }
  .xl-pl-25 {
    padding-left: 25px;
  }
  .xl-pr-25 {
    padding-right: 25px;
  }
  .xl-mb-25 {
    margin-bottom: 25px;
  }
  .xl-ml-25 {
    margin-left: 25px;
  }
  .xl-mr-25 {
    margin-right: 25px;
  }
  .xl-pb-30 {
    padding-bottom: 30px;
  }
  .xl-pl-30 {
    padding-left: 30px;
  }
  .xl-pr-30 {
    padding-right: 30px;
  }
  .xl-mb-30 {
    margin-bottom: 30px;
  }
  .xl-ml-30 {
    margin-left: 30px;
  }
  .xl-mr-30 {
    margin-right: 30px;
  }
  .xl-pb-35 {
    padding-bottom: 35px;
  }
  .xl-pl-35 {
    padding-left: 35px;
  }
  .xl-pr-35 {
    padding-right: 35px;
  }
  .xl-mb-35 {
    margin-bottom: 35px;
  }
  .xl-ml-35 {
    margin-left: 35px;
  }
  .xl-mr-35 {
    margin-right: 35px;
  }
  .xl-pb-40 {
    padding-bottom: 40px;
  }
  .xl-pl-40 {
    padding-left: 40px;
  }
  .xl-pr-40 {
    padding-right: 40px;
  }
  .xl-mb-40 {
    margin-bottom: 40px;
  }
  .xl-ml-40 {
    margin-left: 40px;
  }
  .xl-mr-40 {
    margin-right: 40px;
  }
  .xl-pb-45 {
    padding-bottom: 45px;
  }
  .xl-pl-45 {
    padding-left: 45px;
  }
  .xl-pr-45 {
    padding-right: 45px;
  }
  .xl-mb-45 {
    margin-bottom: 45px;
  }
  .xl-ml-45 {
    margin-left: 45px;
  }
  .xl-mr-45 {
    margin-right: 45px;
  }
  .xl-pb-50 {
    padding-bottom: 50px;
  }
  .xl-pl-50 {
    padding-left: 50px;
  }
  .xl-pr-50 {
    padding-right: 50px;
  }
  .xl-mb-50 {
    margin-bottom: 50px;
  }
  .xl-ml-50 {
    margin-left: 50px;
  }
  .xl-mr-50 {
    margin-right: 50px;
  }
  .xl-pb-55 {
    padding-bottom: 55px;
  }
  .xl-pl-55 {
    padding-left: 55px;
  }
  .xl-pr-55 {
    padding-right: 55px;
  }
  .xl-mb-55 {
    margin-bottom: 55px;
  }
  .xl-ml-55 {
    margin-left: 55px;
  }
  .xl-mr-55 {
    margin-right: 55px;
  }
  .xl-pb-60 {
    padding-bottom: 60px;
  }
  .xl-pl-60 {
    padding-left: 60px;
  }
  .xl-pr-60 {
    padding-right: 60px;
  }
  .xl-mb-60 {
    margin-bottom: 60px;
  }
  .xl-ml-60 {
    margin-left: 60px;
  }
  .xl-mr-60 {
    margin-right: 60px;
  }
  .xl-pb-65 {
    padding-bottom: 65px;
  }
  .xl-pl-65 {
    padding-left: 65px;
  }
  .xl-pr-65 {
    padding-right: 65px;
  }
  .xl-mb-65 {
    margin-bottom: 65px;
  }
  .xl-ml-65 {
    margin-left: 65px;
  }
  .xl-mr-65 {
    margin-right: 65px;
  }
  .xl-pb-70 {
    padding-bottom: 70px;
  }
  .xl-pl-70 {
    padding-left: 70px;
  }
  .xl-pr-70 {
    padding-right: 70px;
  }
  .xl-mb-70 {
    margin-bottom: 70px;
  }
  .xl-ml-70 {
    margin-left: 70px;
  }
  .xl-mr-70 {
    margin-right: 70px;
  }
  .xl-pb-75 {
    padding-bottom: 75px;
  }
  .xl-pl-75 {
    padding-left: 75px;
  }
  .xl-pr-75 {
    padding-right: 75px;
  }
  .xl-mb-75 {
    margin-bottom: 75px;
  }
  .xl-ml-75 {
    margin-left: 75px;
  }
  .xl-mr-75 {
    margin-right: 75px;
  }
  .xl-pb-80 {
    padding-bottom: 80px;
  }
  .xl-pl-80 {
    padding-left: 80px;
  }
  .xl-pr-80 {
    padding-right: 80px;
  }
  .xl-mb-80 {
    margin-bottom: 80px;
  }
  .xl-ml-80 {
    margin-left: 80px;
  }
  .xl-mr-80 {
    margin-right: 80px;
  }
  .xl-pb-85 {
    padding-bottom: 85px;
  }
  .xl-pl-85 {
    padding-left: 85px;
  }
  .xl-pr-85 {
    padding-right: 85px;
  }
  .xl-mb-85 {
    margin-bottom: 85px;
  }
  .xl-ml-85 {
    margin-left: 85px;
  }
  .xl-mr-85 {
    margin-right: 85px;
  }
  .xl-pb-90 {
    padding-bottom: 90px;
  }
  .xl-pl-90 {
    padding-left: 90px;
  }
  .xl-pr-90 {
    padding-right: 90px;
  }
  .xl-mb-90 {
    margin-bottom: 90px;
  }
  .xl-ml-90 {
    margin-left: 90px;
  }
  .xl-mr-90 {
    margin-right: 90px;
  }
  .xl-pb-95 {
    padding-bottom: 95px;
  }
  .xl-pl-95 {
    padding-left: 95px;
  }
  .xl-pr-95 {
    padding-right: 95px;
  }
  .xl-mb-95 {
    margin-bottom: 95px;
  }
  .xl-ml-95 {
    margin-left: 95px;
  }
  .xl-mr-95 {
    margin-right: 95px;
  }
  .xl-t-left {
    text-align: left;
  }
  .xl-t-center {
    text-align: center;
  }
  .xl-t-right {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .lg-mb-0 {
    margin-bottom: 0px;
  }
  .lg-mt-0 {
    margin-top: 0px;
  }
  .lg-ml-0 {
    margin-left: 0px;
  }
  .lg-pt-0 {
    padding-top: 0px;
  }
  .lg-pb-0 {
    padding-bottom: 0px;
  }
  .lg-pl-0 {
    padding-left: 0px;
  }
  .lg-pr-0 {
    padding-right: 0px;
  }
  .lg-mb-5 {
    margin-bottom: 5px;
  }
  .lg-mt-5 {
    margin-top: 5px;
  }
  .lg-ml-5 {
    margin-left: 5px;
  }
  .lg-pt-5 {
    padding-top: 5px;
  }
  .lg-pb-5 {
    padding-bottom: 5px;
  }
  .lg-pl-5 {
    padding-left: 5px;
  }
  .lg-pr-5 {
    padding-right: 5px;
  }
  .lg-mb-10 {
    margin-bottom: 10px;
  }
  .lg-mt-10 {
    margin-top: 10px;
  }
  .lg-ml-10 {
    margin-left: 10px;
  }
  .lg-pt-10 {
    padding-top: 10px;
  }
  .lg-pb-10 {
    padding-bottom: 10px;
  }
  .lg-pl-10 {
    padding-left: 10px;
  }
  .lg-pr-10 {
    padding-right: 10px;
  }
  .lg-mb-15 {
    margin-bottom: 15px;
  }
  .lg-mt-15 {
    margin-top: 15px;
  }
  .lg-ml-15 {
    margin-left: 15px;
  }
  .lg-pt-15 {
    padding-top: 15px;
  }
  .lg-pb-15 {
    padding-bottom: 15px;
  }
  .lg-pl-15 {
    padding-left: 15px;
  }
  .lg-pr-15 {
    padding-right: 15px;
  }
  .lg-mb-20 {
    margin-bottom: 20px;
  }
  .lg-mt-20 {
    margin-top: 20px;
  }
  .lg-ml-20 {
    margin-left: 20px;
  }
  .lg-pt-20 {
    padding-top: 20px;
  }
  .lg-pb-20 {
    padding-bottom: 20px;
  }
  .lg-pl-20 {
    padding-left: 20px;
  }
  .lg-pr-20 {
    padding-right: 20px;
  }
  .lg-mb-25 {
    margin-bottom: 25px;
  }
  .lg-mt-25 {
    margin-top: 25px;
  }
  .lg-ml-25 {
    margin-left: 25px;
  }
  .lg-pt-25 {
    padding-top: 25px;
  }
  .lg-pb-25 {
    padding-bottom: 25px;
  }
  .lg-pl-25 {
    padding-left: 25px;
  }
  .lg-pr-25 {
    padding-right: 25px;
  }
  .lg-mb-30 {
    margin-bottom: 30px;
  }
  .lg-mt-30 {
    margin-top: 30px;
  }
  .lg-ml-30 {
    margin-left: 30px;
  }
  .lg-pt-30 {
    padding-top: 30px;
  }
  .lg-pb-30 {
    padding-bottom: 30px;
  }
  .lg-pl-30 {
    padding-left: 30px;
  }
  .lg-pr-30 {
    padding-right: 30px;
  }
  .lg-mb-35 {
    margin-bottom: 35px;
  }
  .lg-mt-35 {
    margin-top: 35px;
  }
  .lg-ml-35 {
    margin-left: 35px;
  }
  .lg-pt-35 {
    padding-top: 35px;
  }
  .lg-pb-35 {
    padding-bottom: 35px;
  }
  .lg-pl-35 {
    padding-left: 35px;
  }
  .lg-pr-35 {
    padding-right: 35px;
  }
  .lg-mb-40 {
    margin-bottom: 40px;
  }
  .lg-mt-40 {
    margin-top: 40px;
  }
  .lg-ml-40 {
    margin-left: 40px;
  }
  .lg-pt-40 {
    padding-top: 40px;
  }
  .lg-pb-40 {
    padding-bottom: 40px;
  }
  .lg-pl-40 {
    padding-left: 40px;
  }
  .lg-pr-40 {
    padding-right: 40px;
  }
  .lg-mb-45 {
    margin-bottom: 45px;
  }
  .lg-mt-45 {
    margin-top: 45px;
  }
  .lg-ml-45 {
    margin-left: 45px;
  }
  .lg-pt-45 {
    padding-top: 45px;
  }
  .lg-pb-45 {
    padding-bottom: 45px;
  }
  .lg-pl-45 {
    padding-left: 45px;
  }
  .lg-pr-45 {
    padding-right: 45px;
  }
  .lg-mb-50 {
    margin-bottom: 50px;
  }
  .lg-mt-50 {
    margin-top: 50px;
  }
  .lg-ml-50 {
    margin-left: 50px;
  }
  .lg-pt-50 {
    padding-top: 50px;
  }
  .lg-pb-50 {
    padding-bottom: 50px;
  }
  .lg-pl-50 {
    padding-left: 50px;
  }
  .lg-pr-50 {
    padding-right: 50px;
  }
  .lg-mb-55 {
    margin-bottom: 55px;
  }
  .lg-mt-55 {
    margin-top: 55px;
  }
  .lg-ml-55 {
    margin-left: 55px;
  }
  .lg-pt-55 {
    padding-top: 55px;
  }
  .lg-pb-55 {
    padding-bottom: 55px;
  }
  .lg-pl-55 {
    padding-left: 55px;
  }
  .lg-pr-55 {
    padding-right: 55px;
  }
  .lg-mb-60 {
    margin-bottom: 60px;
  }
  .lg-mt-60 {
    margin-top: 60px;
  }
  .lg-ml-60 {
    margin-left: 60px;
  }
  .lg-pt-60 {
    padding-top: 60px;
  }
  .lg-pb-60 {
    padding-bottom: 60px;
  }
  .lg-pl-60 {
    padding-left: 60px;
  }
  .lg-pr-60 {
    padding-right: 60px;
  }
  .lg-mb-65 {
    margin-bottom: 65px;
  }
  .lg-mt-65 {
    margin-top: 65px;
  }
  .lg-ml-65 {
    margin-left: 65px;
  }
  .lg-pt-65 {
    padding-top: 65px;
  }
  .lg-pb-65 {
    padding-bottom: 65px;
  }
  .lg-pl-65 {
    padding-left: 65px;
  }
  .lg-pr-65 {
    padding-right: 65px;
  }
  .lg-mb-70 {
    margin-bottom: 70px;
  }
  .lg-mt-70 {
    margin-top: 70px;
  }
  .lg-ml-70 {
    margin-left: 70px;
  }
  .lg-pt-70 {
    padding-top: 70px;
  }
  .lg-pb-70 {
    padding-bottom: 70px;
  }
  .lg-pl-70 {
    padding-left: 70px;
  }
  .lg-pr-70 {
    padding-right: 70px;
  }
  .lg-t-left {
    text-align: left;
  }
  .lg-t-center {
    text-align: center;
  }
  .lg-t-right {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .md-mb-0 {
    margin-bottom: 0px;
  }
  .md-pt-0 {
    padding-top: 0px;
  }
  .md-pb-0 {
    padding-bottom: 0px;
  }
  .md-pl-0 {
    padding-left: 0px;
  }
  .md-pr-0 {
    padding-right: 0px;
  }
  .md-mb-5 {
    margin-bottom: 5px;
  }
  .md-pt-5 {
    padding-top: 5px;
  }
  .md-pb-5 {
    padding-bottom: 5px;
  }
  .md-pl-5 {
    padding-left: 5px;
  }
  .md-pr-5 {
    padding-right: 5px;
  }
  .md-mb-10 {
    margin-bottom: 10px;
  }
  .md-pt-10 {
    padding-top: 10px;
  }
  .md-pb-10 {
    padding-bottom: 10px;
  }
  .md-pl-10 {
    padding-left: 10px;
  }
  .md-pr-10 {
    padding-right: 10px;
  }
  .md-mb-15 {
    margin-bottom: 15px;
  }
  .md-pt-15 {
    padding-top: 15px;
  }
  .md-pb-15 {
    padding-bottom: 15px;
  }
  .md-pl-15 {
    padding-left: 15px;
  }
  .md-pr-15 {
    padding-right: 15px;
  }
  .md-mb-20 {
    margin-bottom: 20px;
  }
  .md-pt-20 {
    padding-top: 20px;
  }
  .md-pb-20 {
    padding-bottom: 20px;
  }
  .md-pl-20 {
    padding-left: 20px;
  }
  .md-pr-20 {
    padding-right: 20px;
  }
  .md-mb-25 {
    margin-bottom: 25px;
  }
  .md-pt-25 {
    padding-top: 25px;
  }
  .md-pb-25 {
    padding-bottom: 25px;
  }
  .md-pl-25 {
    padding-left: 25px;
  }
  .md-pr-25 {
    padding-right: 25px;
  }
  .md-mb-30 {
    margin-bottom: 30px;
  }
  .md-pt-30 {
    padding-top: 30px;
  }
  .md-pb-30 {
    padding-bottom: 30px;
  }
  .md-pl-30 {
    padding-left: 30px;
  }
  .md-pr-30 {
    padding-right: 30px;
  }
  .md-mb-35 {
    margin-bottom: 35px;
  }
  .md-pt-35 {
    padding-top: 35px;
  }
  .md-pb-35 {
    padding-bottom: 35px;
  }
  .md-pl-35 {
    padding-left: 35px;
  }
  .md-pr-35 {
    padding-right: 35px;
  }
  .md-mb-40 {
    margin-bottom: 40px;
  }
  .md-pt-40 {
    padding-top: 40px;
  }
  .md-pb-40 {
    padding-bottom: 40px;
  }
  .md-pl-40 {
    padding-left: 40px;
  }
  .md-pr-40 {
    padding-right: 40px;
  }
  .md-mb-45 {
    margin-bottom: 45px;
  }
  .md-pt-45 {
    padding-top: 45px;
  }
  .md-pb-45 {
    padding-bottom: 45px;
  }
  .md-pl-45 {
    padding-left: 45px;
  }
  .md-pr-45 {
    padding-right: 45px;
  }
  .md-t-left {
    text-align: left;
  }
  .md-t-center {
    text-align: center;
  }
  .md-t-right {
    text-align: right;
  }
}
@media (max-width: 575px) {
  .sm-mb-0 {
    margin-bottom: 0px;
  }
  .sm-mt-0 {
    margin-top: 0px;
  }
  .sm-ml-0 {
    margin-left: 0px;
  }
  .sm-pt-0 {
    padding-top: 0px;
  }
  .sm-pb-0 {
    padding-bottom: 0px;
  }
  .sm-pl-0 {
    padding-left: 0px;
  }
  .sm-pr-0 {
    padding-right: 0px;
  }
  .sm-mb-5 {
    margin-bottom: 5px;
  }
  .sm-mt-5 {
    margin-top: 5px;
  }
  .sm-ml-5 {
    margin-left: 5px;
  }
  .sm-pt-5 {
    padding-top: 5px;
  }
  .sm-pb-5 {
    padding-bottom: 5px;
  }
  .sm-pl-5 {
    padding-left: 5px;
  }
  .sm-pr-5 {
    padding-right: 5px;
  }
  .sm-mb-10 {
    margin-bottom: 10px;
  }
  .sm-mt-10 {
    margin-top: 10px;
  }
  .sm-ml-10 {
    margin-left: 10px;
  }
  .sm-pt-10 {
    padding-top: 10px;
  }
  .sm-pb-10 {
    padding-bottom: 10px;
  }
  .sm-pl-10 {
    padding-left: 10px;
  }
  .sm-pr-10 {
    padding-right: 10px;
  }
  .sm-mb-15 {
    margin-bottom: 15px;
  }
  .sm-mt-15 {
    margin-top: 15px;
  }
  .sm-ml-15 {
    margin-left: 15px;
  }
  .sm-pt-15 {
    padding-top: 15px;
  }
  .sm-pb-15 {
    padding-bottom: 15px;
  }
  .sm-pl-15 {
    padding-left: 15px;
  }
  .sm-pr-15 {
    padding-right: 15px;
  }
  .sm-mb-20 {
    margin-bottom: 20px;
  }
  .sm-mt-20 {
    margin-top: 20px;
  }
  .sm-ml-20 {
    margin-left: 20px;
  }
  .sm-pt-20 {
    padding-top: 20px;
  }
  .sm-pb-20 {
    padding-bottom: 20px;
  }
  .sm-pl-20 {
    padding-left: 20px;
  }
  .sm-pr-20 {
    padding-right: 20px;
  }
  .sm-mb-25 {
    margin-bottom: 25px;
  }
  .sm-mt-25 {
    margin-top: 25px;
  }
  .sm-ml-25 {
    margin-left: 25px;
  }
  .sm-pt-25 {
    padding-top: 25px;
  }
  .sm-pb-25 {
    padding-bottom: 25px;
  }
  .sm-pl-25 {
    padding-left: 25px;
  }
  .sm-pr-25 {
    padding-right: 25px;
  }
  .sm-mb-30 {
    margin-bottom: 30px;
  }
  .sm-mt-30 {
    margin-top: 30px;
  }
  .sm-ml-30 {
    margin-left: 30px;
  }
  .sm-pt-30 {
    padding-top: 30px;
  }
  .sm-pb-30 {
    padding-bottom: 30px;
  }
  .sm-pl-30 {
    padding-left: 30px;
  }
  .sm-pr-30 {
    padding-right: 30px;
  }
  .sm-mb-35 {
    margin-bottom: 35px;
  }
  .sm-mt-35 {
    margin-top: 35px;
  }
  .sm-ml-35 {
    margin-left: 35px;
  }
  .sm-pt-35 {
    padding-top: 35px;
  }
  .sm-pb-35 {
    padding-bottom: 35px;
  }
  .sm-pl-35 {
    padding-left: 35px;
  }
  .sm-pr-35 {
    padding-right: 35px;
  }
  .sm-mb-40 {
    margin-bottom: 40px;
  }
  .sm-mt-40 {
    margin-top: 40px;
  }
  .sm-ml-40 {
    margin-left: 40px;
  }
  .sm-pt-40 {
    padding-top: 40px;
  }
  .sm-pb-40 {
    padding-bottom: 40px;
  }
  .sm-pl-40 {
    padding-left: 40px;
  }
  .sm-pr-40 {
    padding-right: 40px;
  }
  .sm-mb-45 {
    margin-bottom: 45px;
  }
  .sm-mt-45 {
    margin-top: 45px;
  }
  .sm-ml-45 {
    margin-left: 45px;
  }
  .sm-pt-45 {
    padding-top: 45px;
  }
  .sm-pb-45 {
    padding-bottom: 45px;
  }
  .sm-pl-45 {
    padding-left: 45px;
  }
  .sm-pr-45 {
    padding-right: 45px;
  }
  .sm-t-left {
    text-align: left;
  }
  .sm-t-center {
    text-align: center;
  }
  .sm-t-right {
    text-align: right;
  }
}
/*==========================================================================
Top Bar
==========================================================================*/
.header__area-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
}
.header__area-top-bar-left-info {
  margin-right: 80px;
}
.header__area-top-bar-left-info span i {
  color: var(--green);
  padding-right: 60px;
}
.header__area-top-bar-left-info span a {
  position: relative;
  transition: all 0.4s ease-out 0s;
}
.header__area-top-bar-left-info span a:hover {
  color: var(--green);
}
.header__area-top-bar-left-info span a::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 2px;
  left: 20px;
  bottom: 8px;
  background: var(--green);
}
.header__area-top-bar-right-info {
  display: flex;
  align-items: center;
}
.header__area-top-bar-right-info span {
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-weight: 500;
  margin-right: 15px;
  display: inline-block;
}
.header__area-top-bar-right-info ul {
  padding: 0;
  margin: 0;
}
.header__area-top-bar-right-info ul li {
  list-style: none;
  display: inline-block;
  margin-right: 25px;
}
.header__area-top-bar-right-info ul li:last-child {
  margin: 0;
}
.header__area-top-bar-right-info ul li a i {
  color: var(--heading-color);
  transition: all 0.4s ease-out 0s;
}
.header__area-top-bar-right-info ul li a i:hover {
  color: var(--green);
}

@media (max-width: 784px) {
  .header__area-top-bar {
    display: block;
    text-align: center;
  }
  .header__area-top-bar-left-info {
    margin: 0;
  }
  .header__area-top-bar-right-info {
    justify-content: center;
    margin-top: 4px;
  }
}
@media (max-width: 400px) {
  .header__area-top-bar-left-info span i {
    padding-right: 0;
    margin-right: 10px;
  }
  .header__area-top-bar-left-info span a::after {
    display: none;
  }
}
/*==========================================================================
Menu Bar Sticky
==========================================================================*/
.header__sticky-sticky-menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  box-shadow: var(--box-shadow-3);
  animation: header_sticky 1.1s;
  background: var(--white);
  display: block;
}
@keyframes header_sticky {
  0% {
    top: -250px;
  }
  100% {
    top: 0;
  }
}

.header__sticky.header__sticky-sticky-menu .header__area {
  background: var(--heading-color);
}

/*==========================================================================
Menu Bar
==========================================================================*/
.header__area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  border-bottom: 1px solid var(--border-color-1);
  padding: 0 50px;
}
.header__area-menubar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__area-menubar.three {
  background: var(--white);
  padding: 0 35px;
}
.header__area-menubar-left-logo a {
  display: inline-block;
}
.header__area-menubar-left-logo a img {
  max-width: 150px;
  position: relative;
  z-index: 999;
}
.header__area-menubar-right-menu ul {
  padding: 0;
  margin: 0;
}
.header__area-menubar-right-menu ul li {
  display: inline-block;
  position: relative;
  list-style: none;
}
.header__area-menubar-right-menu ul li:hover > a {
  color: var(--green);
}
.header__area-menubar-right-menu ul li:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.header__area-menubar-right-menu ul li .sub-menu {
  position: absolute;
  background: var(--white);
  min-width: 240px;
  transition: all 0.3s ease-out 0s;
  top: 120%;
  opacity: 0;
  box-shadow: var(--box-shadow-4);
  visibility: hidden;
  z-index: 99;
  text-align: left;
}
.header__area-menubar-right-menu ul li .sub-menu li {
  display: block;
  margin: 0;
  border-bottom: 1px solid var(--color-5);
}
.header__area-menubar-right-menu ul li .sub-menu li a {
  color: var(--heading-color);
  padding: 12px 20px;
  transition: all 0.4s ease-out 0s;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 800;
}
.header__area-menubar-right-menu ul li .sub-menu li a::after {
  position: absolute;
  content: "";
  background: var(--green);
  width: 2px;
  transition: all 0.5s ease-out 0s;
  height: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header__area-menubar-right-menu ul li .sub-menu li .sub-menu {
  left: 100%;
  top: -2px;
}
.header__area-menubar-right-menu ul li .sub-menu li:hover > a {
  color: var(--green);
  padding-left: 25px;
}
.header__area-menubar-right-menu ul li .sub-menu li:hover > a::after {
  height: 100%;
}
.header__area-menubar-right-menu ul li .sub-menu li:last-child {
  border: none;
}
.header__area-menubar-right-menu ul li .sub-menu .menu-item-has-children a::before {
  right: 28px;
  background: var(--heading-color);
}
.header__area-menubar-right-menu ul li .sub-menu .menu-item-has-children a::after {
  right: 25px;
  background: var(--heading-color);
}
.header__area-menubar-right-menu ul li ul .sub-menu li .sub-menu {
  color: var(--white);
  cursor: pointer;
}
.header__area-menubar-right-menu ul li a {
  color: var(--white);
  display: block;
  font-size: 16px;
  line-height: px;
  font-weight: 600;
  transition: all 0.4s ease-out 0s;
  text-transform: capitalize;
  padding: 25px 10px;
  font-family: var(--heading-font);
}
.header__area-menubar-right-menu ul li:last-child a {
  padding-right: 0;
}
.header__area-menubar-right-menu ul li.menu-item-has-children > a {
  position: relative;
}
/* .header__area-menubar-right-menu ul li.menu-item-has-children > a::before {
  content: "";
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 21px;
  width: 2px;
  height: 8px;
  background: var(--white);
  transition: all 0.3s ease;
} 
 .header__area-menubar-right-menu ul li.menu-item-has-children > a::after {
  content: "";
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 18px;
  width: 8px;
  height: 2px;
  background: var(--white);
} */
.header__area-menubar-right-menu ul li.menu-item-has-children:hover > a::before {
  transform: translateY(-50%) rotate(90deg);
  opacity: 0;
}
.header__area-menubar-right-menu ul li.menu-item-has-children:hover > a::after {
  background: var(--green);
}
.header__area-menubar-right-menu.three ul li:hover > a {
  color: var(--green);
}
.header__area-menubar-right-menu.three ul li ul .sub-menu li .sub-menu {
  color: var(--heading-color);
}
.header__area-menubar-right-menu.three ul li a {
  color: var(--heading-color);
  padding: 36px 35px;
}
.header__area-menubar-right-menu.three ul li.menu-item-has-children > a::before {
  background: var(--heading-color);
}
.header__area-menubar-right-menu.three ul li.menu-item-has-children > a::after {
  background: var(--heading-color);
}
.header__area-menubar-right-menu.three ul li:last-child a {
  padding-right: 0;
}
.header__area-menubar-right-menu.three ul li.menu-item-has-children:hover > a::after {
  background: var(--green);
}
.header__area-menubar-right-box {
  display: flex;
  align-items: center;
}
.header__area-menubar-right-box-search {
  margin-left: 50px;
}
.header__area-menubar-right-box-search-icon i {
  cursor: pointer;
  position: relative;
  z-index: 9;
}
.header__area-menubar-right-box-search-icon i::before {
  display: block;
  color: var(--white);
}
.header__area-menubar-right-box-search-icon.two i::before {
  color: var(--heading-color);
}
.header__area-menubar-right-box-search-icon.three i::before {
  color: var(--white);
}
.header__area-menubar-right-box-search-box {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  left: 0;
  right: 0;
  height: 0;
  background: var(--heading-color);
  z-index: 999;
  transition: 0.5s;
  overflow: hidden;
}
.header__area-menubar-right-box-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
}
.header__area-menubar-right-box-search-box input {
  background: var(--white);
  color: var(--heading-color);
  border: 0;
}
.header__area-menubar-right-box-search-box button {
  position: absolute;
  right: 15px;
  top: 19px;
  background-color: transparent;
  text-align: center;
  font-size: 22px;
  color: var(--green);
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
}
.header__area-menubar-right-box-search-box.active {
  height: 100%;
  top: 0;
}
.header__area-menubar-right-box-search-box-icon {
  position: absolute;
  right: 50px;
  top: 50px;
  font-size: 22px;
  color: var(--white);
  cursor: pointer;
  transform: rotate(0deg);
}
.header__area-menubar-right-box-search-box-icon:hover {
  animation: rotate 0.4s ease 0s;
}
.header__area-menubar-right-box-search-box-icon i {
  cursor: pointer;
  position: relative;
  z-index: 9;
}
.header__area-menubar-right-box-search-box-icon i::before {
  display: block;
}
.header__area-menubar-right-box-btn {
  margin-left: 10px;
}
.header__area-menubar-right-box-btn .theme-btn::before {
  background: var(--white);
}
.header__area-menubar-right-box-btn .theme-btn::after {
  background: var(--white);
}
.header__area-menubar-right-box-btn .theme-btn:hover {
  color: var(--heading-color);
}
.header__area-menubar-right-box-sidebar-popup-contact-item {
  display: flex;
  margin-bottom: 25px;
  gap: 25px;
}
.header__area-menubar-right-box-sidebar-popup-contact-item-icon {
  margin-top: 8px;
  width: 30px;
}
.header__area-menubar-right-box-sidebar-popup-contact-item-icon i {
  color: var(--green);
  font-size: 30px;
}
.header__area-menubar-right-box-sidebar-popup-contact-item-content span {
  color: var(--color-1);
  display: inline-block;
  margin-bottom: 5px;
}
.header__area-menubar-right-box-sidebar-popup-contact-item-content h6 {
  max-width: 240px;
}
.header__area-menubar-right-box-sidebar-popup-contact-item-content h6 a {
  transition: all 0.4s ease-out 0s;
}
.header__area-menubar-right-box-sidebar-popup-contact-item-content h6 a:hover {
  color: var(--green);
}
.header__area-menubar-right-box-sidebar-popup-contact-item:last-child {
  margin: 0;
}
.header__area-menubar-right-box-sidebar-popup-image {
  margin: 40px 0;
}
.header__area-menubar-right-box-sidebar-popup-social {
  margin-top: 40px;
}
.header__area-menubar-right-box-sidebar-popup-social ul {
  padding: 0;
  margin: 0;
}
.header__area-menubar-right-box-sidebar-popup-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.header__area-menubar-right-box-sidebar-popup-social ul li:last-child {
  margin: 0;
}
.header__area-menubar-right-box-sidebar-popup-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid var(--color-10);
  border-radius: 50%;
  color: var(--color-8);
  transition: all 0.4s ease-out 0s;
}
.header__area-menubar-right-box-sidebar-popup-social ul li a i:hover {
  color: var(--white);
  background: var(--green);
  border-color: var(--green);
}
.header__area-menubar-right.three {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__area.two {
  padding: 40px 50px;
  border: 0;
}
.header__area.two::after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--white);
  opacity: 0.15;
  left: 0;
}
.header__area.two .header__area-menubar-right {
  display: flex;
  align-items: center;
}
.header__area.two .header__area-menubar-right-info {
  margin-right: 80px;
}
.header__area.two .header__area-menubar-right-info span i {
  color: var(--green);
  padding-right: 60px;
}
.header__area.two .header__area-menubar-right-info span a {
  position: relative;
  color: var(--white);
  transition: all 0.4s ease-out 0s;
}
.header__area.two .header__area-menubar-right-info span a:hover {
  color: var(--green);
}
.header__area.two .header__area-menubar-right-info span a::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 2px;
  left: 20px;
  bottom: 8px;
  background: var(--green);
}
.header__area.page {
  border: 0;
}
.header__area.page::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--white);
  opacity: 0.1;
}
.header__area.three {
  border: 0;
}

/*==========================================================================
Menu Box CSS
==========================================================================*/
.header__area-menubar-right-sidebar-popup {
  position: fixed;
  width: 460px;
  height: 100%;
  right: 0;
  overflow: auto;
  transform: translateX(100%);
  top: 0;
  background: var(--heading-color);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 0.3s ease 0s;
  padding: 40px;
  scrollbar-width: none;
}
.header__area-menubar-right-sidebar-popup.three {
  background: var(--white);
}
.header__area-menubar-right-sidebar-popup.two {
  background: var(--white);
}
.header__area-menubar-right-sidebar-popup::-webkit-scrollbar {
  display: none;
}
.header__area-menubar-right-sidebar-popup.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
  right: 0;
}
.header__area-menubar-right-sidebar-popup-icon i {
  cursor: pointer;
  font-size: 30px;
  color: var(--white);
}
.header__area-menubar-right-sidebar-popup-icon.three {
  padding-left: 110px;
  margin-left: 30px;
  border-left: 1px solid var(--border-color-2);
}
.header__area-menubar-right-sidebar-popup-icon.three i {
  color: var(--green);
}
.header__area-menubar-right-sidebar-popup .sidebar-close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  transform: rotate(0);
}
.header__area-menubar-right-sidebar-popup .sidebar-close-btn i::before {
  background: var(--green);
  width: 40px;
  color: var(--white);
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  display: block;
}
.header__area-menubar-right-sidebar-popup .sidebar-close-btn:hover {
  animation: rotate 0.4s ease 0s;
}
.header__area-menubar-right-sidebar-popup-logo {
  margin-bottom: 30px;
}
.header__area-menubar-right-sidebar-popup-logo a {
  display: inline-block;
}
.header__area-menubar-right-sidebar-popup-logo a img {
  max-width: 150px;
  position: relative;
  z-index: 999;
}
.header__area-menubar-right-sidebar-popup-menu {
  width: 100%;
  height: auto;
  max-height: 75%;
  overflow-x: hidden;
  scrollbar-width: none;
  overflow-y: auto;
  margin-top: 50px;
  padding-bottom: 60px;
}
.header__area-menubar-right-sidebar-popup-menu ul {
  padding: 0;
  margin: 0;
}
.header__area-menubar-right-sidebar-popup-menu ul li {
  list-style: none;
}
.header__area-menubar-right-sidebar-popup-menu ul li a {
  padding: 15px 0;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  font-family: var(--heading-font);
  transition: all 0.4s ease;
}
.header__area-menubar-right-sidebar-popup-menu ul li i {
  float: right;
  font-size: 25px;
  position: relative;
  top: 5px;
}
.header__area-menubar-right-sidebar-popup-menu ul li:hover > a {
  color: var(--green);
}
.header__area-menubar-right-sidebar-popup-menu ul li .sub-menu {
  padding-left: 15px;
}
.header__area-menubar-right-sidebar-popup-menu ul li .sub-menu li a {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding: 5px 0;
}
.header__area-menubar-right-sidebar-popup-social ul {
  padding: 0;
  margin: 0;
}
.header__area-menubar-right-sidebar-popup-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.header__area-menubar-right-sidebar-popup-social ul li:last-child {
  margin: 0;
}
.header__area-menubar-right-sidebar-popup-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: var(--color-4);
  border-radius: 50%;
  color: var(--heading-color);
  transition: all 0.4s ease-out 0s;
}
.header__area-menubar-right-sidebar-popup-social ul li a i:hover {
  color: var(--white);
  border-color: var(--green);
  background: var(--green);
}
.header__area-menubar-right-sidebar-popup-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 40px 40px 40px;
  width: 100%;
  background: var(--white);
}
.header__area-menubar-right-sidebar-popup-bottom p {
  margin-bottom: 30px;
}
.header__area-menubar-right-sidebar-popup-bottom p a {
  color: var(--green);
  font-weight: 500;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0.85;
  background: var(--color-6);
  display: none;
  transition: all 0.4s ease-out 0s;
}
.sidebar-overlay.show {
  display: block;
}

/*==========================================================================
Responsive Menu Css
========================================================================== */
.responsive-menu {
  position: absolute;
  top: 7px;
  right: 0;
  width: 100%;
}

.mean-container a.meanmenu-reveal {
  color: var(--heading-color);
  width: 25px;
  height: 31px;
  top: 24px;
  padding: 0;
  transition: all 0s ease-out 0s;
}
.mean-container a.meanmenu-reveal span {
  background: var(--white);
  margin-top: 6px;
}
.mean-container .mean-bar {
  background: transparent;
  padding: 0;
  z-index: 99;
}
.mean-container .mean-bar a i {
  font-size: 32px;
  display: block;
  color: var(--white);
}
.mean-container .mean-nav {
  background: var(--white);
  margin-top: 86px;
  box-shadow: var(--box-shadow-3);
}
.mean-container .mean-nav li:first-child > a {
  border: none;
}
.mean-container .mean-nav ul li a.mean-expand {
  background: var(--green);
  height: 34px;
  color: var(--white);
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--green);
}
.mean-container .mean-nav .sub-menu li a.mean-expand {
  background: var(--green);
  height: 34px;
  color: var(--white);
}
.mean-container .mean-nav .sub-menu li a.mean-expand:hover {
  background: var(--green);
}
.mean-container .mean-nav ul li a i {
  display: none;
}

.mean-container .mean-nav ul li a,
.mean-container .mean-nav ul li li a,
.mean-container .mean-nav li li:first-child > a {
  color: var(--heading-color);
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: capitalize;
  border-top: 1px solid var(--color-5);
  opacity: 1;
}

@media (max-width: 1199px) {
  .header__area-menubar-right-sidebar-popup-icon.three {
    padding-left: 30px;
  }
}
@media (max-width: 1100px) {
  .header__area-menubar-right-box {
    display: none;
  }
}
@media (max-width: 991px) {
  .header__area {
    padding: 0 40px;
  }
  .header__area-menubar {
    display: initial;
  }
  .header__area-menubar-left-logo {
    position: relative;
    padding: 0px 0;
  }
  .header__area-menubar.three {
    padding: 0;
  }
  .header__area-menubar.three .header__area-menubar-left {
    background: var(--white);
  }
  .header__area-menubar.three .header__area-menubar-left-logo > a {
    padding-left: 15px;
  }
  .header__area-menubar.three .header__area-menubar-left .mean-container a.meanmenu-reveal {
    padding-right: 15px;
  }
  .header__area-menubar.three .header__area-menubar-left .mean-container a.meanmenu-reveal span {
    background: var(--heading-color);
  }
  .header__area-menubar.three .header__area-menubar-left .mean-container .mean-bar a i {
    color: var(--heading-color);
  }
  .header__area.two {
    padding: 0 40px;
  }
  .header__area.two .header__area-menubar {
    display: flex;
  }
  .header__area.two .header__area-menubar-right-info {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .header__area.two .header__area-menubar-right-info {
    display: none;
  }
}
@media (max-width: 575px) {
  .header__area {
    padding: 0;
  }
  .header__area.two {
    padding: 0;
  }
  .header__area-menubar-right-sidebar-popup {
    padding: 40px 30px;
    width: 100%;
  }
  .header__area-menubar-right-sidebar-popup .sidebar-close-btn {
    right: 30px;
  }
  .header__area-menubar-right-sidebar-popup-menu {
    margin-top: 20px;
  }
  .header__area-menubar-right-sidebar-popup-menu ul li a {
    padding: 12px 0;
    font-size: 22px;
    line-height: 32px;
  }
  .header__area-menubar-right-sidebar-popup-menu ul li i {
    top: 4px;
  }
  .header__area-menubar-right-sidebar-popup-bottom {
    padding: 0 30px 40px 30px;
  }
}
/*==========================================================================
Banner One
==========================================================================*/
/* .banner__area {
  background-image: url('../../public/website img/Slider Image1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding-top: 120px;
}
.banner__area::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, var(--black) 0%, rgba(14, 19, 23, 0) 100%);
  z-index: -2;
} */
.banner__area-title {
  padding-top: 195px;
  padding-bottom: 220px;
}
.banner__area-title h1 {
  color: var(--white);
  font-size: 80px;
  line-height: 100px;
  max-width: 880px;
}
.banner__area-title h1 span {
  display: inline-block;
  margin-left: 160px;
}
.banner__area-title-video {
  text-align: right;
  margin-top: -65px;
  margin-right: 100px;
}

/*==========================================================================
Banner Two
========================================================================== */
.banner__two {
  position: relative;
  z-index: 1;
}
.banner__two-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 105px;
}
.banner__two-area-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: scale(1);
  transition: all 10s ease-out 0s;
}
.banner__two-area-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  z-index: -1;
  opacity: 0.7;
}
.banner__two-content {
  position: relative;
  z-index: 9;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 270px;
}
.banner__two-content span {
  display: inline-block;
  color: var(--green);
  font-family: var(--heading-font);
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.banner__two-content h1 {
  margin-bottom: 45px;
  color: var(--white);
  font-weight: 500;
}
.banner__two-content .theme-btn::before {
  background: var(--white);
}
.banner__two-content .theme-btn::after {
  background: var(--white);
}
.banner__two-content .theme-btn:hover {
  color: var(--heading-color);
}
.banner__two .swiper-slide-active .banner__two-area-image {
  transform: scale(1.12);
}
.banner__two-dots {
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.banner__two-dots .pagination .swiper-pagination-bullet {
  border-radius: 50%;
  background: var(--green);
  width: 6px;
  height: 6px;
  transition: all 0.4s ease-out 0s;
  opacity: 1;
  margin: 0;
  margin-right: 16px;
  position: relative;
}
.banner__two-dots .pagination .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid var(--green);
  top: 50%;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.banner__two-dots .pagination .swiper-pagination-bullet-active::after {
  opacity: 1;
}
.banner__two-dots .pagination .swiper-pagination-bullet:last-child {
  margin: 0;
}
.banner__two-video {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 52%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 0 12px;
}
.banner__two-video-right span {
  transform: rotate(-90deg);
  display: inline-block;
  position: relative;
  right: -40px;
  color: var(--white);
}
.banner__two-video-right span a {
  display: inline-block;
  transition: all 0.5s ease-out 0s;
}
.banner__two-video-right span a:hover {
  color: var(--green);
}
.banner__two-video-right span a i {
  margin-right: 8px;
}

/*==========================================================================
Banner Three
========================================================================== */
.banner__three {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 100px 40px;
  padding-top: 260px;
}
.banner__three-title {
  margin-left: 140px;
  position: relative;
}
.banner__three-title .subtitle__one {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}
.banner__three-title h1 {
  font-weight: 500;
  margin-bottom: 50px;
}
.banner__three-title h1 span {
  font-size: 60px;
  line-height: 70px;
}
.banner__three-title-video {
  position: absolute;
  bottom: -40px;
  right: 180px;
}
.banner__three-title-video .video__play a {
  color: var(--green);
  border: 1px solid var(--color-11);
}
.banner__three-title-video .video__play a::after {
  border-top: 1px solid var(--green);
}
.banner__three-right {
  -webkit-clip-path: polygon(0 0%, 100% 0, 80% 0, 100% 23%, 100% 100%, 20% 100%, 0% 77%, 0% 25%);
          clip-path: polygon(0 0%, 100% 0, 80% 0, 100% 23%, 100% 100%, 20% 100%, 0% 77%, 0% 25%);
}

@media (max-width: 1565px) {
  .banner__three-title {
    margin-left: 40px;
  }
}
@media (max-width: 1399px) {
  .banner__three-title {
    margin-left: 0;
  }
  .banner__three-title h1 {
    font-size: 70px;
    line-height: 80px;
    max-width: 700px;
  }
  .banner__three-title-video {
    bottom: 10px;
    right: 100px;
  }
  .banner__two-video {
    padding: 0 62px;
  }
}
@media (max-width: 1199px) {
  .banner__area-title-video {
    text-align: left;
  }
  .banner__three-title h1 {
    font-size: 64px;
    line-height: 74px;
  }
  .banner__three-title-video {
    bottom: 0;
    right: 50px;
  }
}
@media (max-width: 1100px) {
  .banner__two-video {
    top: 55%;
  }
  .banner__three-title h1 {
    font-size: 56px;
    line-height: 66px;
  }
  .banner__three-title h1 span {
    font-size: 56px;
    line-height: 66px;
  }
}
@media (max-width: 991px) {
  .banner__area {
    padding-top: 0px;
  }
  .banner__area-title {
    padding-top: 175px;
    padding-bottom: 200px;
  }
  .banner__area-title h1 {
    font-size: 65px;
    line-height: 85px;
  }
  .banner__area-title h1 span {
    margin-left: 90px;
  }
  .banner__area-title-video {
    margin: 0;
  }
  .banner__two-area {
    padding-top: 94px;
  }
  .banner__two-content {
    padding-top: 180px;
    padding-bottom: 260px;
  }
  .banner__two-content h1 {
    font-size: 65px;
    line-height: 85px;
  }
}
@media (max-width: 784px) {
  .banner__three {
    padding-top: 282px;
  }
}
@media (max-width: 767px) {
  .banner__area-title h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .banner__area-title h1 span {
    margin-left: 70px;
  }
  .banner__two-content h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .banner__two-video {
    padding: 0 20px;
  }
}
@media (max-width: 575px) {
  .banner__two-video {
    padding: 0 12px;
    top: 78%;
  }
  .banner__three {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 540px) {
  .banner__area-title {
    text-align: center;
    padding-top: 160px;
    padding-bottom: 190px;
  }
  .banner__area-title h1 span {
    margin-left: 0;
  }
  .banner__area-title-video {
    margin-top: 30px;
    text-align: center;
  }
  .banner__three-title h1 {
    font-size: 50px;
    line-height: 60px;
  }
  .banner__three-title h1 span {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 465px) {
  .banner__area-title h1 {
    font-size: 38px;
    line-height: 50px;
  }
  .banner__two-content h1 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 35px;
  }
  .banner__three-title h1 {
    font-size: 38px;
    line-height: 48px;
    max-width: 340px;
    margin-bottom: 35px;
  }
  .banner__three-title h1 span {
    font-size: 38px;
    line-height: 48px;
  }
  .banner__three-title-video {
    bottom: -9px;
    right: auto;
    left: 240px;
  }
  .banner__three-title-video .video__play a {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}
@media (max-width: 359px) {
  .banner__area-title h1 {
    font-size: 33px;
    line-height: 45px;
  }
  .banner__two-content h1 {
    font-size: 33px;
    line-height: 45px;
  }
  .banner__three-title h1 {
    font-size: 34px;
    line-height: 44px;
  }
  .banner__three-title h1 span {
    font-size: 32px;
    line-height: 42px;
  }
  .banner__three-title-video {
    bottom: -5px;
    left: 224px;
  }
  .banner__three-title-video .video__play a {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}
/*==========================================================================
Accommodations
==========================================================================*/
.accommodations__area-title h2 {
  margin-bottom: 14px;
  max-width: 425px;
}
.accommodations__area-title p {
  margin-bottom: 55px;
  max-width: 445px;
}
.accommodations__area-right-image {
  position: relative;
  margin: 90px 0 0 0;
}
.accommodations__area-right-image-two {
  position: absolute;
  top: -90px;
  right: 0;
}

/*==========================================================================
Accommodations Two
==========================================================================*/
.accommodations__two-left {
  padding-top: 110px;
  position: relative;
}
.accommodations__two-left-roll {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
.accommodations__two-title {
  margin-left: 110px;
}
.accommodations__two-title h2 {
  margin-bottom: 14px;
}
.accommodations__two-title p {
  margin-bottom: 55px;
}
.accommodations__two-right-bottom {
  display: flex;
  margin-top: 40px;
}
.accommodations__two-right-bottom-signature img {
  max-width: 120px;
}
.accommodations__two-right-bottom-author {
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid var(--color-10);
}
.accommodations__two-right-bottom-author h5 {
  margin-bottom: 4px;
}
.accommodations__two-right-bottom-author span {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  font-family: var(--heading-font);
  color: var(--color-1);
}

@media (max-width: 1399px) {
  .accommodations__two-title {
    margin-left: 50px;
  }
}
@media (max-width: 1199px) {
  .accommodations__two-title {
    margin-left: 0;
  }
  .accommodations__two-right-bottom-author {
    margin-left: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 460px) {
  .accommodations__area-right-image-two {
    max-width: 270px;
  }
}
/*==========================================================================
Services
==========================================================================*/
.services__area-item-icon {
  background: 8e6b43;
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: block;
  text-align: center;
  border-radius: 50%;
  float: left;
  margin-right: 30px;
  color:#fff;
}

.services__area-item-icon:hover{
   background:var(--green);
   transition: 0.3s ease-in-out;
}
.services__area-item-icon img {
  max-width: 40px;
}
.services__area-item-content {
  overflow: hidden;
}
.services__area-item-content h5 {
  margin-bottom: 5px;
}
.services__area-item-content h5 a {
  transition: all 0.4s ease-in-out;
}
.services__area-item-content h5 a:hover {
  color: var(--green);
}
.services__area-item-content p {
  margin: 0;
  max-width: 305px;
}

/*==========================================================================
Services Two
==========================================================================*/
.services__two-item {
 text-align: center;
  border:2px solid var(--brown);
  transition: all 0.4s ease-in-out;
  padding: 40px;
  position: relative;
}
.services__two-item span {
  display: inline-block;
  font-weight: 700;
  font-size: 80px;
  line-height: 90px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  opacity: 0;
  color: var(--heading-color);
  position: absolute;
  top: 35px;
  right: 35px;
  transition: all 0.4s ease-in-out;
  font-family: var(--heading-font);
}
.services__two-item-icon {
  margin-bottom: 25px;
}
.services__two-item-icon img {
  max-width: 60px;
}
.services__two-item-content h4 {
  margin-bottom: 10px;
}
.services__two-item-content h4 a {
  transition: all 0.4s ease-in-out;
}
.services__two-item-content h4 a:hover {
  color: var(--green);
}
.services__two-item-content p {
  margin: 0;
}
.services__two-item:hover {
  border-color: var(--green);
}
.services__two-item:hover span {
  opacity: 0.06;
}

.services__details-left-content span {
  border-left: 3px solid var(--green);
  font-size: 18px;
  line-height: 28px;
  font-family: var(--heading-font);
  font-weight: 600;
  display: inline-block;
  box-shadow: var(--box-shadow-1);
  padding: 30px;
  margin: 30px 0;
}
.services__details-left-content-list p {
  margin-bottom: 5px;
}
.services__details-left-content-list p i {
  color: var(--green);
  margin-right: 10px;
}

@media (max-width: 1199px) {
  .services__area-item-icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin-right: 20px;
  }
  .services__area-item-icon img {
    max-width: 35px;
  }
}
@media (max-width: 767px) {
  .services__area-item-icon {
    width: 90px;
    height: 90px;
    line-height: 90px;
    margin: 0;
    margin-bottom: 15px;
    float: initial;
  }
  .services__area-item-icon img {
    max-width: 40px;
  }
}
@media (max-width: 575px) {
  .services__area-item {
    text-align: center;
  }
  .services__area-item-icon {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .services__area-item-content p {
    margin: 0 auto;
  }
}
/*==========================================================================
Blog
==========================================================================*/
.blog__area-title {
  text-align: center;
}
.blog__area-item-image {
  overflow: hidden;
}
.blog__area-item-image img {
  width: 100%;
  transition: all 0.5s ease-out 0s;
  transform: scale(1);
}
.blog__area-item-image:hover img {
  transform: scale(1.1);
}
.blog__area-item-content {
 
  border: 1px solid var(--color-5);
  margin: 0 20px;
  padding: 35px 30px;
  margin-top: -80px;
  position: relative;
  transition: all 0.5s ease-out 0s;
  margin-bottom: 50px;
  color:#000;
  background:var(--lightbrown);

}
/* .blog__area-item-content:hover{
  background: var(--darkgreen);
  transition:0.4s ease-in-out;
  border-radius: none;
} */
.blog__area-item-content-box {
  display: flex;
  gap: 30px;
}
.blog__area-item-content-box-date {
  min-width: 70px;
  height: 80px;
  border-bottom: 1px solid var(--border-color-2);
}
/* .blog__area-item-content-box-title h5 {
  max-width: 212px;
} */
.blog__area-item-content-box-title h5 a {
  transition: all 0.4s ease-out 0s;
}
.blog__area-item-content-box-title h5 a:hover {
  color: var(--green);
}
.blog__area-item-content-btn {
  padding-left: 100px;
  margin-top: -30px;
  opacity: 0;
  transition: all 0.5s ease-out 0s;
}
.blog__area-item-hover .blog__area-item-content {
  margin-bottom: 0;
}
.blog__area-item-hover .blog__area-item-content-btn {
  margin-top: 20px;
  opacity: 1;
}

/*==========================================================================
Blog Two
==========================================================================*/
.blog__two-title {
  text-align: center;
}
.blog__two-item-image {
  position: relative;
  overflow: hidden;
}
.blog__two-item-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: var(--heading-color);
  opacity: 0;
  z-index: 0;
  transition: all 0.4s ease-out 0s;
}
.blog__two-item-image-date {
  position: absolute;
  left: 30px;
  bottom: 25px;
  background: var(--green);
  display: inline-block;
  padding: 5px 12px;
  text-align: center;
  z-index: 1;
}
.blog__two-item-image-date h5 {
  color: var(--white);
}
.blog__two-item-image-date span {
  color: var(--white);
}
.blog__two-item-image img {
  width: 100%;
  transition: all 0.5s ease-out 0s;
  transform: scale(1);
}
.blog__two-item-content {
  box-shadow: var(--box-shadow-1);
  padding: 35px;
  padding-top: 20px;
}
.blog__two-item-content h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
}
.blog__two-item-content h4 {
  margin-bottom: 25px;
}
.blog__two-item-content h4 a {
  transition: all 0.4s ease-out 0s;
}
.blog__two-item-content h4 a:hover {
  color: var(--green);
}
.blog__two-item:hover .blog__two-item-image img {
  transform: scale(1.1);
}
.blog__two-item:hover .blog__two-item-image::after {
  height: 100%;
  opacity: 0.8;
}

/*==========================================================================
Blog Standard
==========================================================================*/
.blog__standard-left-item {
  margin-bottom: 30px;
}
.blog__standard-left-item:last-child {
  margin: 0;
}
.blog__standard-left-item-image {
  overflow: hidden;
}
.blog__standard-left-item-image img {
  width: 100%;
  transition: all 0.5s ease-out 0s;
  transform: scale(1);
}
.blog__standard-left-item-image:hover img {
  transform: scale(1.1);
}
.blog__standard-left-item-content {
  padding: 0 45px 45px 45px;
  border: 1px solid var(--border-color-2);
  border-top: 0;
}
.blog__standard-left-item-content-meta {
  padding: 30px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color-2);
}
.blog__standard-left-item-content-meta ul {
  padding: 0;
  margin: 0;
}
.blog__standard-left-item-content-meta ul li {
  display: inline-block;
  margin-right: 25px;
  padding-right: 25px;
  border-right: 1px solid var(--border-color-2);
}
.blog__standard-left-item-content-meta ul li a {
  transition: all 0.4s ease-out 0s;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: var(--heading-font);
  color: var(--color-1);
}
.blog__standard-left-item-content-meta ul li a i {
  color: var(--green);
  margin-right: 10px;
}
.blog__standard-left-item-content-meta ul li a:hover {
  color: var(--green);
}
.blog__standard-left-item-content-meta ul li:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}
.blog__standard-left-item-content h3 {
  margin-bottom: 25px;
}
.blog__standard-left-item-content h3 a {
  transition: all 0.4s ease-out 0s;
}
.blog__standard-left-item-content h3 a:hover {
  color: var(--green);
}

/*==========================================================================
Blog Details
==========================================================================*/
.blog__details-left {
  border: 1px solid var(--border-color-2);
  padding: 35px;
}
.blog__details-left-meta {
  padding: 30px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color-2);
}
.blog__details-left-meta ul {
  padding: 0;
  margin: 0;
}
.blog__details-left-meta ul li {
  display: inline-block;
  margin-right: 25px;
  padding-right: 25px;
  border-right: 1px solid var(--border-color-2);
}
.blog__details-left-meta ul li a {
  transition: all 0.4s ease-out 0s;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: var(--heading-font);
  color: var(--color-1);
}
.blog__details-left-meta ul li a i {
  color: var(--green);
  margin-right: 10px;
}
.blog__details-left-meta ul li a:hover {
  color: var(--green);
}
.blog__details-left-meta ul li:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}
.blog__details-left-box {
  background: var(--color-4);
  padding: 50px 40px;
  position: relative;
  margin: 35px 0;
}
.blog__details-left-box-icon {
  position: absolute;
  max-width: 160px;
  bottom: 50px;
  right: 190px;
  z-index: 0;
}
.blog__details-left-box p {
  font-size: 20px;
  line-height: 30px;
  text-transform: none;
  color: var(--heading-color);
  margin-bottom: 30px;
  max-width: 768px;
  position: relative;
  z-index: 1;
}
.blog__details-left-box h5 {
  position: relative;
  display: inline-block;
  padding-left: 60px;
}
.blog__details-left-box h5::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  top: 16px;
  background: var(--green);
}
.blog__details-left-comment-item {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color-2);
}
.blog__details-left-comment-item-comment-image {
  float: left;
}
.blog__details-left-comment-item-comment-image img {
  max-width: 100px;
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.blog__details-left-comment-item-comment-content {
  padding-left: 30px;
  overflow: hidden;
}
.blog__details-left-comment-item-comment-content span {
  font-size: 14px;
  margin-bottom: 15px;
  display: inline-block;
}
.blog__details-left-comment-item-comment-content h5 a {
  float: right;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.4s ease-out 0s;
  font-style: italic;
}
.blog__details-left-comment-item-comment-content h5 a i {
  margin-right: 10px;
}
.blog__details-left-comment-item-comment-content h5 a:hover {
  color: var(--green);
}
.blog__details-left-contact-form-item {
  position: relative;
}
.blog__details-left-contact-form-item i {
  position: absolute;
  right: 20px;
  top: 23px;
  color: var(--green);
}
.blog__details-left-contact-form-item input {
  font-size: 14px;
  background: transparent;
}
.blog__details-left-contact-form-item textarea {
  height: 220px;
  font-size: 14px;
  background: transparent;
}
.blog__details-left-contact-form-item .theme-btn i {
  position: relative;
  top: initial;
  right: initial;
  color: var(--white);
  transition: all 0.4s ease-out 0s;
}

@media (max-width: 1399px) {
  .blog__area-item-content {
    padding: 30px 25px;
  }
  .blog__area-item-content-box-title h5 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .blog__standard-left-item-content {
    padding: 0 25px 25px 25px;
  }
  .blog__standard-left-item-content-meta {
    padding: 15px 0;
    margin-bottom: 20px;
  }
  .blog__standard-left-item-content-meta ul li {
    margin-right: 15px;
    padding-right: 15px;
  }
  .blog__standard-left-item-content h3 {
    margin-bottom: 20px;
  }
  .blog__details-left {
    padding: 20px;
  }
  .blog__details-left-meta {
    padding: 15px 0;
    margin-bottom: 20px;
  }
  .blog__details-left-meta ul li {
    margin-right: 15px;
    padding-right: 15px;
  }
  .blog__details-left-box {
    padding: 40px 30px;
  }
  .blog__details-left-box-icon {
    right: 40px;
  }
}
@media (max-width: 575px) {
  .blog__details-left-box {
    padding: 24px;
  }
  .blog__details-left-box p {
    font-size: 16px;
    line-height: 26px;
  }
  .blog__details-left-comment-item-comment-image {
    float: initial;
    margin-bottom: 20px;
  }
  .blog__details-left-comment-item-comment-content {
    padding-left: 0;
  }
}
@media (max-width: 420px) {
  .blog__area-item-content {
    padding: 30px 20px;
  }
  .blog__area-item-content-box {
    gap: 20px;
  }
  .blog__area-item-content-box-title h5 {
    font-size: 18px;
    line-height: 28px;
  }
  .blog__area-item-content-btn {
    padding-left: 90px;
  }
  .blog__standard-left-item-content {
    padding: 0 20px 25px 20px;
  }
  .blog__standard-left-item-content-meta ul li {
    margin-right: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 359px) {
  .blog__area-item-content {
    margin: 0 10px;
    margin-top: -80px;
    margin-bottom: 0;
  }
  .blog__area-item-content-box {
    gap: 15px;
  }
  .blog__area-item-content-box-title h5 {
    font-size: 16px;
    line-height: 26px;
  }
  .blog__area-item-content-btn {
    padding-left: 85px;
  }
  .blog__details-left {
    padding: 15px;
  }
}
/*==========================================================================
Single Room
==========================================================================*/
.room__area {
  background: var(--color-4);
}
.room__area-title h2 {
  margin-bottom: 14px;
}
.room__area-title p {
  margin-bottom: 55px;
}
.room__area-title-contact {
  display: flex;
  align-items: center;
  gap: 20px;
}
.room__area-title-contact-icon i {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--color-10);
  font-size: 20px;
  color: var(--green);
}
.room__area-title-contact-content span {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  font-family: var(--heading-font);
}
.room__area-title-contact-content span a {
  color: var(--heading-color);
  transition: all 0.4s ease-out 0s;
}
.room__area-title-contact-content span a:hover {
  color: var(--green);
}
.room__area-image img {
  max-height: 340px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.room__area-list {
  margin-left: 35px;
}
.room__area-list-item {
  margin-bottom: 30px;
}
.room__area-list-item:last-child {
  margin: 0;
}
.room__area-list-item-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: block;
  text-align: center;
  border-radius: 50%;
  float: left;
  margin-right: 20px;
  border: 1px solid var(--color-10);
}
.room__area-list-item-icon img {
  max-width: 30px;
}
.room__area-list-item-content {
  overflow: hidden;
}
.room__area-list-item-content h5 {
  margin-bottom: 5px;
}
.room__area-list-item-content p {
  margin: 0;
  max-width: 240px;
}

/*==========================================================================
Room List
==========================================================================*/
.room__list-item {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color-2);
  padding: 0;
  margin-bottom: 30px;
}
.room__list-item:last-child {
  margin: 0;
}
.room__list-item-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 69%;
  padding: 0 50px;
}
.room__list-item-right-content {
  border-right: 1px solid var(--border-color-2);
  padding-right: 65px;
}
.room__list-item-right-content p {
  color: var(--color-1);
  max-width: 330px;
}
.room__list-item-right-content ul {
  padding: 0;
  margin: 0;
}
.room__list-item-right-content ul li {
  display: inline-block;
  list-style: none;
  margin-right: 25px;
}
.room__list-item-right-content ul li:last-child {
  margin: 0;
}
.room__list-item-right-content ul li i {
  color: var(--green);
  margin-right: 10px;
}
.room__list-item-right-meta {
  text-align: center;
}
.room__list-item-right-meta-top > span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: var(--heading-font);
  color: var(--green);
}
.room__list-item-right-meta-top p {
  margin: 0;
  font-size: 13px;
  line-height: 23px;
}
.room__list-item-right-meta-top p i {
  color: var(--green);
  display: inline-block;
  margin-right: 8px;
}
.room__list-item-right-meta-top p span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--heading-color);
  display: inline-block;
  margin-right: 10px;
}

/*==========================================================================
Room Details
==========================================================================*/
.room__details-right-content {
  padding-bottom: 45px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border-color-2);
}
.room__details-right-list-item {
  display: flex;
  align-items: center;
}
.room__details-right-list-item-icon {
  margin-right: 20px;
}
.room__details-right-list-item-icon img {
  max-width: 30px;
}
.room__details-right-faq-item {
  margin-bottom: 3px;
  background: var(--color-4);
  padding: 0 35px;
}
.room__details-right-faq-item-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 15px 0;
}
.room__details-right-faq-item-card-header i {
  color: var(--heading-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background: var(--white);
}
.room__details-right-faq-item-card-header-content {
  padding: 0px 0 35px 0;
}
.room__details-right-faq-item-card-header-content p {
  margin: 0;
  padding-right: 40px;
}

@media (max-width: 1399px) {
  .room__list-item-right {
    width: 90%;
  }
  .room__list-item-right-content {
    padding-right: 30px;
  }
}
@media (max-width: 1199px) {
  .room__list-item-right {
    width: 69%;
  }
  .room__list-item-right-content {
    padding-right: 65px;
  }
}
@media (max-width: 991px) {
  .room__area-list {
    margin-top: 55px;
    margin-left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -moz-column-gap: 20px;
         column-gap: 20px;
  }
  .room__list-item-right {
    width: 100%;
    padding: 0 20px;
  }
  .room__list-item-right-content {
    border-right: 0;
    padding-right: 10px;
  }
  .room__list-item-right-content ul li {
    margin-right: 25px;
  }
}
@media (max-width: 767px) {
  .room__area-list {
    grid-template-columns: initial;
  }
  .room__list-item {
    display: block;
  }
  .room__list-item-image img {
    width: 100%;
  }
  .room__list-item-right {
    display: block;
    padding: 25px 30px 30px 30px;
  }
  .room__list-item-right-content {
    padding: 0;
  }
  .room__list-item-right-content h4 {
    margin-bottom: 5px;
  }
  .room__list-item-right-content p {
    margin-bottom: 5px;
  }
  .room__list-item-right-meta {
    text-align: left;
  }
  .room__list-item-right-meta-top {
    display: flex;
    gap: 30px;
    margin: 10px 0;
  }
}
@media (max-width: 767px) {
  .room__list-item-right {
    padding: 25px 20px 30px 20px;
  }
  .room__details-right-faq-item {
    padding: 0 20px;
  }
  .room__details-right-faq-item-card-header {
    padding: 15px 0;
  }
  .room__details-right-faq-item-card-header h5 {
    font-size: 16px;
    line-height: 26px;
  }
  .room__details-right-faq-item-card-header i {
    width: initial;
    height: initial;
    line-height: initial;
    background: transparent;
  }
  .room__details-right-faq-item-card-header-content {
    padding: 0 0 25px 0;
  }
  .room__details-right-faq-item-card-header-content p {
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .order-lg-second {
    order: 0 !important;
  }
}
/*==========================================================================
Deluxe
==========================================================================*/
.deluxe__area {
  background: var(--color-4);
}
.deluxe__area-btn {
  text-align: right;
}
.deluxe__area-btn ul {
  padding: 0;
  margin: 0;
}
.deluxe__area-btn ul li {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  font-weight: 600;
  font-family: var(--heading-font);
  color: var(--heading-color);
  background: var(--white);
  padding: 11px 25px 13px 25px;
  margin-right: 10px;
}
.deluxe__area-btn ul li.active {
  background: var(--green);
  color: var(--white);
}
.deluxe__area-btn ul li:last-child {
  margin: 0;
}
.deluxe__area-item {
  position: relative;
}
.deluxe__area-item-image {
  position: relative;
}
.deluxe__area-item-image img {
  min-height: 357px;
  -o-object-fit: cover;
     object-fit: cover;
}
.deluxe__area-item-image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 0;
  /* background-image: linear-gradient(180deg, rgba(14, 19, 23, 0) 0%, #0E1317 100%); */
  transition: all 0.4s ease-out 0s;
}
.deluxe__area-item-content {
  position: absolute;
  bottom: -57px;
  left: 0;
  padding: 35px;
  width: 100%;
  transition: all 0.4s ease-in-out;
}
.deluxe__area-item-content h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--white);
  margin-bottom: 4px;
}
.deluxe__area-item-content h6 span {
  color: var(--green);
}
.deluxe__area-item-content h4 a {
  color: var(--white);
  transition: all 0.4s ease-in-out;
}
.deluxe__area-item-content h4 a:hover {
  color: var(--green);
}
.deluxe__area-item-content .simple-btn {
  position: relative;
  padding-top: 10px;
  margin-top: 15px;
  display: block;
  opacity: 0;
  color: var(--green);
}
.deluxe__area-item-content .simple-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  border-top: 1px solid #000;
  opacity: 0.5;
}
.deluxe__area-item-content .simple-btn i {
  color: var(--green);
  border-color: var(--green);
}
.deluxe__area-item-content .simple-btn:hover {
  color: var(--green);
}
.deluxe__area-item-content .simple-btn:hover i {
  color: var(--green);
  border-color: var(--green);
}
.deluxe__area-item-hover .deluxe__area-item-content {
  bottom: 0;
}
.deluxe__area-item-hover .deluxe__area-item-content .simple-btn {
  opacity: 1;
}

/*==========================================================================
Deluxe Two
==========================================================================*/
.deluxe__two-item-content {
  padding: 30px;
  padding-top: 20px;
  border: 1px solid var(--border-color-2);
  border-top: 0;
  background: var(--white);
  position: relative;
  transition: all 0.4s ease-out 0s;
}
.deluxe__two-item-content h4 a {
  transition: all 0.5s ease-out 0s;
}
.deluxe__two-item-content h4 a:hover {
  color: var(--green);
}
.deluxe__two-item-content p {
  margin: 8px 0;
  max-width: 230px;
}
.deluxe__two-item-content > span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: var(--heading-font);
  color: var(--white);
  background: var(--green);
  padding: 6px 15px;
  position: absolute;
  left: 20px;
  top: -55px;
}
.deluxe__two-item-content-meta ul {
  padding: 0;
  margin: 0;
}
.deluxe__two-item-content-meta ul li {
  display: inline-block;
  list-style: none;
  margin-right: 25px;
}
.deluxe__two-item-content-meta ul li:last-child {
  margin: 0;
}
.deluxe__two-item-content-meta ul li i {
  color: var(--green);
  margin-right: 10px;
}
.deluxe__two-item-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -45px;
  padding-top: 15px;
  border-top: 1px solid var(--border-color-2);
  opacity: 0;
  transition: all 0.4s ease-out 0s;
}
.deluxe__two-item-content-bottom p {
  margin: 0;
  font-size: 13px;
  line-height: 23px;
}
.deluxe__two-item-content-bottom p i {
  color: var(--green);
  display: inline-block;
  margin-right: 8px;
}
.deluxe__two-item-content-bottom p span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--heading-color);
  display: inline-block;
  margin-right: 10px;
}
.deluxe__two-item:hover .deluxe__two-item-content {
  margin-top: -60px;
}
.deluxe__two-item:hover .deluxe__two-item-content-bottom {
  margin-top: 15px;
  opacity: 1;
}

/*==========================================================================
Deluxe Two
==========================================================================*/
.deluxe__three-right {
  text-align: right;
}
.deluxe__three-item-image {
  position: relative;
}
.deluxe__three-item-image::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: var(--heading-color);
  opacity: 0;
  z-index: 0;
  transition: all 0.4s ease-out 0s;
}
.deluxe__three-item-image-content {
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 22px;
  background: var(--white);
  position: relative;
  transition: all 0.4s ease-out 0s;
  position: absolute;
  z-index: 1;
  bottom: 50px;
  opacity: 0;
  left: 30px;
  right: 30px;
}
.deluxe__three-item-image-content h4 {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deluxe__three-item-image-content h4 a {
  transition: all 0.5s ease-out 0s;
}
.deluxe__three-item-image-content h4 a:hover {
  color: var(--green);
}
.deluxe__three-item-image-content h4 span {
  color: var(--green);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.deluxe__three-item-image-content p {
  margin-bottom: 15px;
}
.deluxe__three-item-image-content-meta ul {
  padding: 0;
  margin: 0;
}
.deluxe__three-item-image-content-meta ul li {
  display: inline-block;
  list-style: none;
  margin-right: 25px;
}
.deluxe__three-item-image-content-meta ul li:last-child {
  margin: 0;
}
.deluxe__three-item-image-content-meta ul li i {
  color: var(--green);
  margin-right: 10px;
}
.deluxe__three-item-image-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid var(--border-color-2);
}
.deluxe__three-item-image-content-bottom p {
  margin: 0;
  font-size: 13px;
  line-height: 23px;
}
.deluxe__three-item-image-content-bottom p i {
  color: var(--green);
  display: inline-block;
  margin-right: 8px;
}
.deluxe__three-item-image-content-bottom p span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--heading-color);
  display: inline-block;
  margin-right: 10px;
}
.deluxe__three-item:hover .deluxe__three-item-image::after {
  height: 100%;
  opacity: 0.8;
}
.deluxe__three-item:hover .deluxe__three-item-image-content {
  bottom: 20px;
  opacity: 1;
}
.deluxe__three-item.center {
  margin-top: -90px;
}

@media (max-width: 1399px) {
  .deluxe__two-item-content-meta ul li {
    margin-right: 17px;
  }
  .deluxe__two-item-content-meta ul li:last-child {
    margin: 0;
  }
}
@media (max-width: 1199px) {
  .deluxe__area-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .deluxe__area-btn {
    text-align: center;
  }
  .deluxe__three-item.center {
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .deluxe__three-item-image-content {
    left: 15px;
    right: 15px;
  }
  .deluxe__three-item:hover .deluxe__three-item-image-content {
    bottom: 15px;
  }
}
@media (max-width: 767px) {
  .deluxe__area-btn ul li {
    margin: 5px;
    padding: 11px 15px 13px 15px;
  }
  .deluxe__area-btn ul li:last-child {
    margin: 5px;
  }
  .deluxe__three-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .deluxe__three-right {
    text-align: center;
  }
}
@media (max-width: 359px) {
  .deluxe__three-item-image-content {
    padding: 20px;
    padding-bottom: 22px;
    left: 15px;
    right: 15px;
  }
  .deluxe__three-item-image-content h4 {
    font-size: 22px;
    line-height: 32px;
  }
}
/*==========================================================================
Footer
==========================================================================*/
.footer__area {
  background: var(--heading-color);
  padding-top: 90px;
}
.footer__area-widget h5 {
  color: var(--white);
}
.footer__area-widget-about-logo {
  margin-bottom: 25px;
}
.footer__area-widget-about-logo a {
  display: inline-block;
}
.footer__area-widget-about-logo a img {
  max-width: 150px;
}
.footer__area-widget-about p {
  color: var(--color-3);
  margin-bottom: 30px;
  max-width: 250px;
}
.footer__area-widget-about-social ul {
  padding: 0;
  margin: 0;
}
.footer__area-widget-about-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.footer__area-widget-about-social ul li:last-child {
  margin: 0;
}
.footer__area-widget-about-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid var(--color-8);
  border-radius: 50%;
  color: var(--color-9);
  transition: all 0.4s ease-out 0s;
}
.footer__area-widget-about-social ul li a i:hover {
  color: var(--white);
  border-color: var(--green);
  background: var(--green);
}
.footer__area-widget-about.two p {
  max-width: 410px;
}
.footer__area-widget-contact {
  margin-top: 30px;
}
.footer__area-widget-contact-item {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;
}
.footer__area-widget-contact-item:last-child {
  margin: 0;
}
.footer__area-widget-contact-item-icon {
  width: 30px;
}
.footer__area-widget-contact-item-icon i {
  color: var(--green);
  font-size: 26px;
}
.footer__area-widget-contact-item-content span {
  max-width: 170px;
  display: inline-block;
}
.footer__area-widget-contact-item-content span a {
  color: var(--color-3);
  transition: all 0.4s ease-out 0s;
}
.footer__area-widget-contact-item-content span a:hover {
  color: var(--green);
}
.footer__area-widget-menu {
  margin-top: 30px;
}
.footer__area-widget-menu ul {
  padding: 0;
  margin: 0;
}
.footer__area-widget-menu ul li {
  list-style: none;
  margin-bottom: 10px;
}
.footer__area-widget-menu ul li:last-child {
  margin: 0;
}
.footer__area-widget-menu ul li a {
  color:var(--lightbrown);
  transition: all 0.4s ease-out 0s;
}
.footer__area-widget-menu ul li a i {
  opacity: 0;
  transition: all 0.4s ease-out 0s;
  margin-right: -8px;
}
.footer__area-widget-menu ul li a:hover {
  color: var(--green);
}
.footer__area-widget-menu ul li a:hover i {
  opacity: 1;
  margin-right: 8px;
}
.footer__area-widget-subscribe {
  margin-top: 35px;
}
.footer__area-widget-subscribe form {
  position: relative;
}
.footer__area-widget-subscribe form input {
  background: transparent;
  border-color: var(--color-7);
  height: 70px;
  color: var(--color-3);
}
.footer__area-widget-subscribe form input:focus {
  color: var(--color-3);
}
.footer__area-widget-subscribe form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
  padding: 0;
}
.footer__area-widget-subscribe form button:focus {
  color: var(--white);
}

/*==========================================================================
Footer Two
==========================================================================*/
.footer__two {
  background: var(--color-4);
  padding-top: 90px;
}
.footer__two-widget-about-logo {
  margin-bottom: 25px;
}
.footer__two-widget-about-logo a {
  display: inline-block;
}
.footer__two-widget-about-logo a img {
  max-width: 150px;
}
.footer__two-widget-about p {
  margin-bottom: 30px;
  max-width: 345px;
}
.footer__two-widget-about-social ul {
  padding: 0;
  margin: 0;
}
.footer__two-widget-about-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.footer__two-widget-about-social ul li:last-child {
  margin: 0;
}
.footer__two-widget-about-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: var(--white);
  border-radius: 50%;
  color: var(--color-9);
  transition: all 0.4s ease-out 0s;
}
.footer__two-widget-about-social ul li a i:hover {
  color: var(--white);
  background: var(--green);
}
.footer__two-widget-post {
  margin-top: 30px;
}
.footer__two-widget-post-item {
  margin-bottom: 15px;
}
.footer__two-widget-post-item span {
  color: var(--color-1);
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  font-family: var(--heading-font);
}
.footer__two-widget-post-item span i {
  color: var(--green);
  margin-right: 10px;
  font-size: 16px;
}
.footer__two-widget-post-item h6 {
  font-size: 14px;
  line-height: 24px;
  color: var(--body-color);
  font-weight: 600;
  max-width: 160px;
}
.footer__two-widget-post-item h6 a {
  transition: all 0.4s ease-out 0s;
}
.footer__two-widget-post-item h6 a:hover {
  color: var(--green);
}
.footer__two-widget-post-item:last-child {
  margin: 0;
}
.footer__two-widget-menu {
  margin-top: 30px;
}
.footer__two-widget-menu ul {
  padding: 0;
  margin: 0;
}
.footer__two-widget-menu ul li {
  list-style: none;
  margin-bottom: 10px;
}
.footer__two-widget-menu ul li:last-child {
  margin: 0;
}
.footer__two-widget-menu ul li a {
  color: var(--body-color);
  transition: all 0.4s ease-out 0s;
}
.footer__two-widget-menu ul li a i {
  opacity: 0;
  transition: all 0.4s ease-out 0s;
  margin-right: -8px;
}
.footer__two-widget-menu ul li a:hover {
  color: var(--green);
}
.footer__two-widget-menu ul li a:hover i {
  opacity: 1;
  margin-right: 8px;
}
.footer__two-widget-hours {
  margin-top: 30px;
  max-width: 300px;
}
.footer__two-widget-hours p {
  margin: 0;
  border-bottom: 1px dashed var(--color-1);
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.footer__two-widget-hours p span {
  float: right;
}
.footer__two-widget-hours p:last-child {
  border: 0;
  margin: 0;
  padding: 0;
}

/*==========================================================================
Copyright
==========================================================================*/
.copyright__area {
  margin-top: 90px;
  padding: 40px 0;
  border-top: 1px solid var(--color-7);
}
.copyright__area-left p {
  color: var(--white);
  margin: 0;
}
.copyright__area-left p a {
  color: var(--green);
}
.copyright__area-right ul {
  padding: 0;
  margin: 0;
}
.copyright__area-right ul li {
  display: inline-block;
  list-style: none;
}
.copyright__area-right ul li a {
  color: var(--color-3);
  transition: all 0.4s ease-out 0s;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 2px solid var(--color-1);
}
.copyright__area-right ul li a:hover {
  color: var(--green);
}
.copyright__area-right ul li:last-child a {
  margin: 0;
  padding: 0;
  border: 0;
}

/*==========================================================================
Copyright Two
==========================================================================*/
.copyright__two {
  margin-top: 90px;
  padding: 40px 0;
  border-top: 1px solid var(--color-11);
}
.copyright__two-title {
  text-align: center;
}
.copyright__two-title p {
  color: var(--heading-color);
  margin: 0;
}
.copyright__two-title p a {
  color: var(--green);
}

/*==========================================================================
Coming Soon
==========================================================================*/
.coming__soon {
  padding-top: 240px;
  padding-bottom: 260px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.coming__soon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--heading-color);
  z-index: -1;
  opacity: 0.8;
}
.coming__soon-title {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}
.coming__soon-title h1 {
  font-size: 120px;
  line-height: 1;
  font-weight: 600;
  color: var(--white);
}
.coming__soon-title .countdown {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 55px;
}
.coming__soon-title .countdown-item {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.coming__soon-title .countdown-item h3 {
  color: var(--white);
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 5px;
}
.coming__soon-title .countdown-item span {
  color: var(--white);
  text-transform: uppercase;
  font-family: var(--heading-font);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.coming__soon-title .subscribe {
  margin-top: 30px;
  padding-top: 50px;
  position: relative;
}
.coming__soon-title .subscribe::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--white);
  opacity: 0.2;
}
.coming__soon-title .subscribe h6 {
  color: var(--white);
  margin-bottom: 30px;
}
.coming__soon-title .subscribe form {
  max-width: 630px;
  margin: 0 auto;
  display: flex;
}
.coming__soon-title .subscribe form button {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--heading-font);
}

@media (max-width: 1399px) {
  .coming__soon {
    padding-top: 110px;
    padding-bottom: 125px;
  }
  .coming__soon-title {
    max-width: 600px;
  }
  .coming__soon-title h1 {
    font-size: 90px;
  }
  .coming__soon-title .countdown {
    margin-top: 40px;
  }
  .coming__soon-title .subscribe form {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .coming__soon-title h1 {
    font-size: 70px;
  }
  .coming__soon-title .countdown {
    margin-top: 30px;
  }
}
@media (max-width: 465px) {
  .coming__soon-title h1 {
    font-size: 46px;
  }
  .coming__soon-title .countdown {
    margin-top: 30px;
  }
  .coming__soon-title .countdown-item h3 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 0;
  }
  .coming__soon-title .countdown-item span {
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
  }
  .coming__soon-title .subscribe {
    margin-top: 25px;
    padding-top: 40px;
  }
  .coming__soon-title .subscribe h6 {
    font-size: 15px;
    line-height: 25px;
  }
  .coming__soon-title .subscribe form button {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 16px 15px;
  }
}
/*==========================================================================
Feature
==========================================================================*/
.feature__area-left-title {
  border:3px solid var(--green);
  padding: 0 0 0 80px;
  background-color:#f8f8f8;
}
.feature__area-left-title h2 {
  margin-bottom: 14px;
}
.feature__area-left-title p {
  margin-bottom: 55px;
  max-width: 420px;
}
.feature__area .bg-left {
  position: relative;
  z-index: 1;
  padding: 50px 0;
}
.feature__area .bg-left::before {
  position: absolute;
  content: "";
  right: 12px;
  top: 0;
  width: 90%;
  height: 100%;
  /* background:var(--darkgreen); */
  background-color:var(--brown);
  z-index: -1;
}
.feature__area .bg-left::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  background: var(--white);
  z-index: -1;
}
.feature__area .bg-right {
  position: relative;
  z-index: 1;
  padding: 50px 0;
}
.feature__area .bg-right::before {
  position: absolute;
  content: "";
  left: 12px;
  top: 0;
  width: 90%;
  height: 100%;
  background: var(--brown);
  z-index: -1;
}
.feature__area .bg-right::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 25%;
  height: 100%;
  background: var(--white);
  z-index: -1;
}

/*==========================================================================
Video
==========================================================================*/
.video__area {
  background-image: url('../../public/img/video.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  padding: 180px 0;
}
.video__area::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--heading-color);
  z-index: -2;
  opacity: 0.7;
}
.video__area-title h2 {
  color: var(--white);
}
.video__area-right {
  text-align: right;
}
.video__area-right .video__play a {
  width: 150px;
  height: 150px;
  line-height: 150px;
}

/*==========================================================================
Call
==========================================================================*/
.call__area-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 70px;
  margin: 0;
}
.call__area-bg.three {
  background: var(--color-4);
}
.call__area-bg.three::after {
  display: none;
}
.call__area-bg.three .call__area-bg-left h2 {
  color: var(--heading-color);
}
.call__area-bg::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--heading-color);
  z-index: -1;
  opacity: 0.8;
}
.call__area-bg-left h2 {
  color: var(--white);
}
.call__area-bg-right {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: end;
}
.call__area-bg-right-icon i {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--color-4);
  font-size: 25px;
  color: var(--white);
}
.call__area-bg-right-content span {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  font-family: var(--heading-font);
}
.call__area-bg-right-content span a {
  color: var(--white);
  transition: all 0.4s ease-out 0s;
}
.call__area-bg-right-content span a:hover {
  color: var(--green);
}
.call__area-bg-right.three .call__area-bg-right-icon i {
  border: 1px solid var(--color-10);
  color: var(--green);
}
.call__area-bg-right.three .call__area-bg-right-content span a {
  color: var(--heading-color);
}
.call__area-bg-right.three .call__area-bg-right-content span a:hover {
  color: var(--green);
}

/*==========================================================================
Place
==========================================================================*/
.place__area-title h2 {
  margin-bottom: 14px;
  max-width: 485px;
}
.place__area-title p {
  margin-bottom: 55px;
  max-width: 525px;
}
.place__area-image {
  margin-right: 90px;
}
.place__area-image-two {
  margin-top: 60px;
}

/*==========================================================================
Place Two
==========================================================================*/
.place__two-title h2 {
  margin-bottom: 14px;
  max-width: 485px;
}
.place__two-title p {
  margin-bottom: 55px;
  max-width: 485px;
}
.place__two-left {
  margin-right: 60px;
}
.place__two-left-image {
  display: flex;
}
.place__two-left-image-item {
  margin-left: -30px;
}
.place__two-left-image-item:first-child {
  margin: 0;
}

/*==========================================================================
Band
==========================================================================*/
.band__area-border {
  border-top: 1px solid var(--border-color-3);
  padding: 100px 0;
}
.band__area-item {
  text-align: center;
}
.band__area-item img {
  max-width: 140px;
}

/*==========================================================================
Company
==========================================================================*/
.company__area-title h2 {
  margin-bottom: 14px;
}
.company__area-title p {
  margin-bottom: 55px;
}
.company__area-image {
  margin: 0 70px 0 45px;
}

/*==========================================================================
Offers
==========================================================================*/
.offers__area-title {
  text-align: center;
}
.offers__area-title h2 {
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: 14px;
}
.offers__area-title p {
  max-width: 890px;
  margin: 0 auto;
  margin-bottom: 55px;
}

/*==========================================================================
Pagination
========================================================================== */
.theme__pagination ul {
  margin: 0;
  padding: 0;
}
.theme__pagination ul li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
}
.theme__pagination ul li:last-child {
  margin: 0;
}
.theme__pagination ul li .active {
  background: var(--green);
  color: var(--white);
  border-color: var(--green);
}
.theme__pagination ul li a {
  color: var(--heading-color);
  font-family: var(--heading-font);
  border: 1px solid var(--border-color-2);
  display: block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}
.theme__pagination ul li a:hover {
  background: var(--green);
  color: var(--white);
  border-color: var(--green);
}

@media (max-width: 1399px) {
  .company__area-left h1 {
    font-size: 72px;
    line-height: 82px;
  }
  .company__area-image {
    margin: 0;
  }
  .company__area-image img {
    min-height: 380px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 1199px) {
  .feature__area-left-title {
    padding: 0 0 0 40px;
  }
  .call__area-bg {
    padding: 60px 30px;
  }
  .call__area-bg-left {
    text-align: center;
    margin-bottom: 40px;
  }
  .call__area-bg-right {
    justify-content: center;
  }
  .place__area-image {
    margin-right: 0;
  }
  .company__area-left h1 {
    font-size: 60px;
    line-height: 70px;
  }
  .company__area-left h5 {
    font-size: 16px;
    line-height: 26px;
  }
  .place__two-left {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .feature__area-left-title {
    background: var(--color-4);
    padding: 30px;
  }
  .feature__area .bg-left {
    padding: 0;
  }
  .feature__area .bg-left::before {
    display: none;
  }
  .feature__area .bg-left::after {
    display: none;
  }
  .feature__area .bg-right {
    padding: 0;
  }
  .feature__area .bg-right::before {
    display: none;
  }
  .feature__area .bg-right::after {
    display: none;
  }
  .video__area-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .video__area-right {
    text-align: center;
  }
  .company__area-image img {
    min-height: initial;
  }
}
@media (max-width: 390px) {
  .call__area-bg {
    padding: 50px 10px;
  }
  .call__area-bg-right {
    gap: 20px;
  }
}
@media (max-width: 359px) {
  .feature__area-left-title {
    padding: 30px 20px;
  }
  .call__area-bg-right-icon i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-style: 20px;
  }
  .call__area-bg-right-content span {
    font-size: 18px;
    line-height: 28px;
  }
}
/*==========================================================================
Booking
==========================================================================*/
.booking__area {
  position: relative;
  z-index: 1;
  padding-top: 120px;
}
.booking__area::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--heading-color);
  z-index: -1;
  opacity: 0.75;
}
.booking__area-form {
  background: var(--white);
  padding: 60px;
  box-shadow: var(--box-shadow-1);
  margin-bottom: -35px;
}
.booking__area-form-check-item {
  width: 100%;
}
.booking__area-form-check-item input {
  color: var(--color-1);
  background: transparent;
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 14px;
  padding: 0 18px;
  cursor: pointer;
}
.booking__area-form-check-item input:focus {
  color: var(--color-1);
}
.booking__area-form-check-item-room .nice-select {
  color: var(--color-1);
  font-family: var(--heading-font);
  font-weight: 600;
  font-size: 14px;
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  border: 1px solid var(--border-color-2);
  border-radius: 0;
  height: 60px;
  line-height: 60px;
  padding: 0 18px;
  margin-bottom: 20px;
}
.booking__area-form-check-item-room .nice-select::after {
  width: 8px;
  height: 8px;
  right: 20px;
  margin-top: -3px;
  border-bottom: 2px solid var(--green);
  border-right: 2px solid var(--green);
  transition: all 0.4s ease-out 0s;
}
.booking__area-form-check-item-room .nice-select .list {
  padding: 0;
  margin: 0;
  border-radius: 0;
  width: 100%;
  transition: all 0.4s ease-out 0s;
}
.booking__area-form-check-item-room .nice-select .list li {
  color: var(--heading-color);
  font-weight: 600;
}
.booking__area-form-check-item-room .nice-select .list .option.selected.focus {
  color: var(--green);
}

/*==========================================================================
Booking Two
==========================================================================*/
.booking__two {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.booking__two-title {
  text-align: center;
}

@media (max-width: 575px) {
  .booking__area-form {
    padding: 50px 30px;
  }
}
@media (max-width: 359px) {
  .booking__area-form {
    padding: 45px 25px;
  }
}
/*==========================================================================
Check
==========================================================================*/
.check__area {
  /* background: var(--heading-color); */
  /* background: var(--brown); */
  background:  #FDDC5C;
  display: flex;
  justify-content: space-between;
}
.check__area-item {
  padding: 24px 40px;
  width: 100%;
  border-right: 1px solid var(--color-7);
}
.check__area-item p {
  color: #000;
  font-family: var(--heading-font);
  font-weight: 700;
  margin: 0;
  font-size: 1.2rem;
}
.check__area-item p input {
  color: var(--green);
  background: transparent;
  border: 0;
  font-family: var(--heading-font);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 24px;
  padding: 0;
  height: initial;
}
.check__area-item p input:focus {
  color: var(--green);
}
.check__area-item-room .nice-select {
  color: var(--green);
  font-family: var(--heading-font);
  font-weight: 600;
  font-size: 14px;
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  height: initial;
  line-height: initial;
  top: 5px;
}
.check__area-item-room .nice-select::after {
  width: 8px;
  height: 8px;
  right: 4px;
  margin-top: -3px;
  border-bottom: 2px solid var(--green);
  border-right: 2px solid var(--green);
  transition: all 0.4s ease-out 0s;
}
.check__area-item-room .nice-select .list {
  padding: 0;
  margin: 0;
  margin-top: 27px;
  border-radius: 0;
  width: 100%;
  transition: all 0.4s ease-out 0s;
}
.check__area-item-room .nice-select .list li {
  color: var(--heading-color);
  font-weight: 600;
}
.check__area-item-room .nice-select .list .option.selected.focus {
  color: var(--green);
}
.check__area-item.button {
  padding: 0;
  border: 0;
}
.check__area-item.button .theme-btn {
  width: 100%;
  height: 100%;
  justify-content: center;
}
.check__area-item.button .theme-btn::before {
  background: var(--white);
}
.check__area-item.button .theme-btn::after {
  background: var(--white);
}
.check__area-item.button .theme-btn:hover {
  color: var(--heading-color);
}
.check__area.two {
  background: var(--white);
}
.check__area.two .check__area-item {
  border-color: var(--color-5);
}
.check__area.two .check__area-item.button .theme-btn::before {
  background: var(--heading-color);
}
.check__area.two .check__area-item.button .theme-btn::after {
  background: var(--heading-color);
}
.check__area.two .check__area-item.button .theme-btn:hover {
  color: var(--white);
}

@media (max-width: 991px) {
  .check__area {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .check__area {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .check__area-item-room .nice-select .list {
    margin-top: 19px;
  }
  .check__area.two .check__area-item {
    border: 0;
    border-bottom: 1px solid;
    border-color: var(--color-5);
  }
}
/*==========================================================================
Team
==========================================================================*/
.team__area-title {
  text-align: center;
}
.team__area-item-image {
  position: relative;
}
.team__area-item-image::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: var(--heading-color);
  opacity: 0;
  z-index: 0;
  transition: all 0.4s ease-out 0s;
}
.team__area-item-image-overlay {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}
.team__area-item-image-overlay-social {
  margin-bottom: 45px;
  transition: all 0.4s ease-out 0s;
  opacity: 0;
}
.team__area-item-image-overlay-social ul {
  padding: 0;
  margin: 0;
}
.team__area-item-image-overlay-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.team__area-item-image-overlay-social ul li:last-child {
  margin: 0;
}
.team__area-item-image-overlay-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid var(--color-1);
  border-radius: 50%;
  color: var(--color-9);
  transition: all 0.4s ease-out 0s;
}
.team__area-item-image-overlay-social ul li a i:hover {
  color: var(--white);
  border-color: var(--green);
  background: var(--green);
}
.team__area-item-image-overlay-content {
  background: var(--white);
  padding: 30px;
  text-align: center;
  margin: 0 25px;
}
.team__area-item-image-overlay-content span {
  font-weight: 500;
  font-family: var(--heading-font);
  color: var(--green);
}
.team__area-item:hover .team__area-item-image::after {
  height: 100%;
  opacity: 0.8;
}
.team__area-item:hover .team__area-item-image-overlay-social {
  opacity: 1;
  margin-bottom: 25px;
}

/*==========================================================================
Services Team
==========================================================================*/
.services__team-item-image {
  position: relative;
}
.services__team-item-image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: var(--heading-color);
  opacity: 0;
  z-index: 0;
  transition: all 0.4s ease-out 0s;
}
.services__team-item-image-content {
  background: var(--white);
  padding: 30px 0;
  text-align: center;
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
  z-index: 2;
}
.services__team-item-image-content span {
  font-weight: 500;
  font-family: var(--heading-font);
  color: var(--green);
}
.services__team-item-image-content-social {
  transition: all 0.4s ease-out 0s;
  margin-top: -45px;
  opacity: 0;
}
.services__team-item-image-content-social ul {
  padding: 0;
  margin: 0;
}
.services__team-item-image-content-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.services__team-item-image-content-social ul li:last-child {
  margin: 0;
}
.services__team-item-image-content-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid var(--color-12);
  border-radius: 50%;
  color: var(--color-9);
  transition: all 0.4s ease-out 0s;
}
.services__team-item-image-content-social ul li a i:hover {
  color: var(--white);
  border-color: var(--green);
  background: var(--green);
}
.services__team-item:hover .services__team-item-image::after {
  height: 100%;
  opacity: 0.8;
}
.services__team-item:hover .services__team-item-image-content-social {
  margin-top: 10px;
  opacity: 1;
}

/*==========================================================================
Testimonial
==========================================================================*/
.testimonial__area-bg {
  background: var(--heading-color);
  padding: 55px 70px;
  position: relative;
}
.testimonial__area-item {
  display: flex;
  align-items: center;
  gap: 30px;
}
.testimonial__area-item-image img {
  min-width: 220px;
  border-radius: 150px;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonial__area-item-content {
  margin-top: -35px;
}
.testimonial__area-item-content h4 {
  color: var(--white);
}
.testimonial__area-item-content p {
  color: var(--white);
  max-width: 780px;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
}
.testimonial__area-item-content span {
  color: var(--green);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: var(--heading-font);
  margin-bottom: 20px;
  display: block;
}
.testimonial__area-item-dots {
  position: absolute;
  left: 330px;
  bottom: 65px;
  z-index: 1;
}
.testimonial__area-item-dots .pagination .swiper-pagination-bullet {
  border-radius: 50%;
  background: var(--green);
  width: 6px;
  height: 6px;
  transition: all 0.4s ease-out 0s;
  opacity: 1;
  margin: 0;
  margin-right: 25px;
  position: relative;
}
.testimonial__area-item-dots .pagination .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid var(--green);
  top: 50%;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.testimonial__area-item-dots .pagination .swiper-pagination-bullet-active::after {
  opacity: 1;
}

@media (max-width: 991px) {
  .testimonial__area-bg {
    padding: 0;
  }
  .testimonial__area-item {
    gap: 25px;
  }
  .testimonial__area-item-image {
    padding: 35px 0 35px 30px;
  }
  .testimonial__area-item-content {
    margin: 0;
    padding-right: 30px;
  }
  .testimonial__area-item-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .testimonial__area-item-dots {
    left: 52%;
    bottom: 30px;
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .testimonial__area-bg {
    padding: 40px 30px;
    padding-bottom: 60px;
  }
  .testimonial__area-item {
    display: block;
    text-align: center;
  }
  .testimonial__area-item-image {
    padding: 0;
    margin-bottom: 30px;
  }
  .testimonial__area-item-image img {
    width: initial;
  }
  .testimonial__area-item-content {
    padding: 0;
  }
}
@media (max-width: 505px) {
  .testimonial__area-bg {
    padding: 40px 25px;
    padding-bottom: 60px;
  }
  .testimonial__area-item-image {
    margin-bottom: 25px;
  }
  .testimonial__area-item-content p {
    font-size: 15px;
    line-height: 26px;
  }
}
/*==========================================================================
All Side Bar
========================================================================== */
.all__sidebar-item {
  border: 1px solid var(--border-color-2);
  padding: 30px;
  margin-bottom: 30px;
}
.all__sidebar-item h5 {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.all__sidebar-item h5::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 60px;
  background: var(--green);
}
.all__sidebar-item:last-child {
  margin: 0;
}
.all__sidebar-item-category ul {
  padding: 0;
  margin: 0;
}
.all__sidebar-item-category ul li {
  list-style: none;
}
.all__sidebar-item-category ul li a {
  display: block;
  color: var(--heading-color);
  font-family: var(--body-font);
  transition: all 0.4s ease-out 0s;
  margin-bottom: 10px;
  padding-bottom: 14px;
  font-weight: 400;
  border-bottom: 1px solid var(--border-color-2);
}
.all__sidebar-item-category ul li a i {
  margin-right: 5px;
}
.all__sidebar-item-category ul li a span {
  float: right;
  position: relative;
  top: 2px;
}
.all__sidebar-item-category ul li a:hover {
  color: var(--green);
  margin-left: 5px;
}
.all__sidebar-item-category ul li:last-child a {
  margin-bottom: 0;
}
.all__sidebar-item-category ul li .active {
  color: var(--green);
  margin-left: 5px;
}
.all__sidebar-item-price ul {
  padding: 0;
  margin: 0;
}
.all__sidebar-item-price ul li {
  list-style: none;
  margin-bottom: 10px;
}
.all__sidebar-item-price ul li:last-child {
  margin: 0;
}
.all__sidebar-item-price ul li i {
  color: var(--green);
  margin-right: 10px;
}
.all__sidebar-item-price h4 {
  margin: 25px 0;
}
.all__sidebar-item-price h4 span {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: var(--color-1);
}
.all__sidebar-item-booking-item .nice-select {
  color: var(--heading-color);
  border: 1px solid var(--border-color-2);
  border-radius: 0;
  padding: 0 15px;
  background: transparent;
  width: 100%;
  height: 60px;
  line-height: 60px;
  float: initial;
  font-size: 15px;
}
.all__sidebar-item-booking-item .nice-select::after {
  width: 9px;
  height: 9px;
  right: 18px;
  margin-top: -5px;
  border-bottom: 1px solid var(--color-1);
  border-right: 1px solid var(--color-1);
  transition: all 0.4s ease-out 0s;
}
.all__sidebar-item-booking-item .nice-select .list {
  margin: 0;
  border-radius: 0;
  width: 100%;
  transition: all 0.4s ease-out 0s;
}
.all__sidebar-item-booking-item .nice-select .list li {
  color: var(--heading-color);
}
.all__sidebar-item-booking-item .nice-select .list .option.selected.focus {
  color: var(--green);
  font-weight: 500;
}
.all__sidebar-item-search form {
  position: relative;
}
.all__sidebar-item-search form input {
  background: transparent;
  height: 70px;
}
.all__sidebar-item-search form button {
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--green);
  bottom: 0;
  display: inline-block;
}
.all__sidebar-item-post-item {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color-2);
}
.all__sidebar-item-post-item-image {
  width: 80px;
  height: 80px;
  min-width: 80px;
  overflow: hidden;
}
.all__sidebar-item-post-item-image img {
  transform: scale(1);
  transition: all 0.4s ease-out 0s;
  -o-object-fit: cover;
     object-fit: cover;
}
.all__sidebar-item-post-item-image:hover img {
  transform: scale(1.04);
}
.all__sidebar-item-post-item-content span {
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  font-family: var(--heading-font);
  color: var(--color-1);
}
.all__sidebar-item-post-item-content span i {
  color: var(--green);
  margin-right: 8px;
  font-size: 14px;
}
.all__sidebar-item-post-item-content h6 a {
  transition: all 0.4s ease-out 0s;
  display: inline-block;
}
.all__sidebar-item-post-item-content h6 a:hover {
  color: var(--green);
}
.all__sidebar-item-post-item:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}
.all__sidebar-item-tag ul {
  padding: 0;
  margin: 0;
  margin-left: -5px;
  margin-top: -5px;
}
.all__sidebar-item-tag ul li {
  display: inline-block;
  list-style: none;
}
.all__sidebar-item-tag ul li a {
  transition: all 0.4s ease-out 0s;
  border: 1px solid var(--border-color-2);
  margin: 5px;
  padding: 5px 20px 8px 20px;
  display: inline-block;
}
.all__sidebar-item-tag ul li a:hover {
  color: var(--white);
  background: var(--green);
  border-color: var(--green);
}
.all__sidebar-item-help {
  position: relative;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 40px;
  text-align: center;
}
.all__sidebar-item-help::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: var(--green);
  opacity: 0.8;
}
.all__sidebar-item-help-icon {
  margin-bottom: 35px;
}
.all__sidebar-item-help-icon i {
  width: 90px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  background: var(--white);
  color: var(--green);
}
.all__sidebar-item-help h5 {
  color: var(--white);
  margin-bottom: 15px;
}
.all__sidebar-item-help-contact-content span {
  color: var(--white);
  font-weight: 500;
  font-family: var(--heading-font);
  display: inline-block;
}
.all__sidebar-item-help-contact-content h6 {
  color: var(--white);
}

/*==========================================================================
Instagram
==========================================================================*/
.instagram__area {
  padding: 0 25px;
  margin-bottom: 10px;
}
.instagram__area-item {
  position: relative;
  z-index: 1;
}
.instagram__area-item::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: var(--heading-color);
  opacity: 0;
  z-index: 0;
  transition: all 0.4s ease-out 0s;
}
.instagram__area-item img {
  width: 100%;
}
.instagram__area-item-icon {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: all 0.4s ease-out 0s;
}
.instagram__area-item-icon a i {
  color: var(--white);
  font-size: 45px;
  line-height: 55px;
}
.instagram__area-item:hover::after {
  opacity: 0.6;
  height: 100%;
}
.instagram__area-item:hover .instagram__area-item-icon {
  opacity: 1;
  top: 50%;
}

@media (max-width: 767px) {
  .instagram__area {
    padding: 0 5px;
  }
}
/*==========================================================================
Page
========================================================================== */
.page__banner {
  background-image: url('../../public/img/banner-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 125px;
  padding-bottom: 125px;
}
.page__banner::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--heading-color);
  opacity: 0.8;
}
.page__banner-title {
  text-align: center;
}
.page__banner-title h1 {
  color: var(--white);
  font-weight: 500;
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 10px;
}
.page__banner-title ul {
  padding: 0;
  margin: 0;
}
.page__banner-title ul li {
  list-style: none;
  display: inline-block;
  font-family: var(--heading-font);
  color: var(--green);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.page__banner-title ul li span {
  color: var(--white);
  display: inline-block;
  margin: 0 10px 0 5px;
}
.page__banner-title ul li a {
  color: var(--white);
  transition: all 0.4s ease-in-out;
}
.page__banner-title ul li a:hover {
  color: var(--green);
}

@media (max-width: 359px) {
  .page__banner-title h1 {
    font-size: 42px;
    line-height: 52px;
  }
}

@media (max-width:400px){
  .page__banner{
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
/*==========================================================================
Contact
==========================================================================*/
.contact__area-title p {
  max-width: 460px;
}
.contact__area-info {
  margin-top: 30px;
}
.contact__area-info-item {
  display: flex;
  margin-bottom: 25px;
  gap: 25px;
}
.contact__area-info-item-icon {
  margin-top: 8px;
}
.contact__area-info-item-icon i {
  color:var(--green);
  /* color: var(--green); */
  font-size: 30px;
}
.contact__area-info-item-content span {
  color: var(--color-1);
  display: inline-block;
  margin-bottom: 5px;
}
.contact__area-info-item-content h6 {
  max-width: 240px;
}
.contact__area-info-item-content h6 a {
  transition: all 0.4s ease-out 0s;
}
.contact__area-info-item-content h6 a:hover {
  color: var(--green);
}
.contact__area-info-item:last-child {
  margin: 0;
}
.contact__area-social {
  margin-top: 40px;
}
.contact__area-social ul {
  padding: 0;
  margin: 0;
}
.contact__area-social ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.contact__area-social ul li:last-child {
  margin: 0;
}
.contact__area-social ul li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid var(--color-10);
  border-radius: 50%;
  color: var(--color-8);
  transition: all 0.4s ease-out 0s;
}
.contact__area-social ul li a i:hover {
  color: var(--white);
  background: var(--green);
  border-color: var(--green);
}
.contact__area-form-item {
  position: relative;
}
.contact__area-form-item i {
  position: absolute;
  left: 20px;
  top: 23px;
  /* color: var(--green); */
  color:var(--green)
}
.contact__area-form-item input {
  font-size: 14px;
  background: transparent;
  padding-left: 45px;
}
.contact__area-form-item textarea {
  height: 220px;
  font-size: 14px;
  background: transparent;
  padding-left: 45px;
}
.contact__area-form-item .theme-btn i {
  position: relative;
  top: initial;
  left: initial;
  color: var(--white);
  transition: all 0.4s ease-out 0s;
}
.contact__area-map iframe {
  width: 100%;
  height: 570px;
  display: block;
  filter: grayscale(1);
}/*# sourceMappingURL=style.css.map */