.testimonial-carousel-container {
    padding: 50px 20px;
    background-color: #f9f9f9;
  }
  
  .testimonial-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: var(--green);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  }
  
  .testimonial-content {
    text-align: center;
  }
  
  .testimonial-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .testimonial-text {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .testimonial-name {
    font-size: 14px;
    color:#fff;
    font-style: italic;
  }
  

  .testimonial-section{
    padding: 5rem 3rem;
    background-image: url('../public/img/banner-2.jpg');
  }