@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


html{
  --brown:#aa7827;
  --green:#117193;
  --lightbrown:#f6c727;
}

.header__area-menubar-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .header__area-menubar-right.menu-responsive {
    display: none;
  }
  
  .header__area-menubar-right.menu-open {
    display: block;
  }
  
  .responsive-menu {
    display: none;
    cursor: pointer;
    height:100%;
  }

  .responsive-menu i{
    font-size: 2.5rem;
    color:#fff;
  }

 
  @media (max-width: 768px) {

    .header__area-menubar-right ul li{
        display: flex;
        flex-direction: column;
        
      }
    .header__area-menubar-right {
      display: none;
    }
  
    .header__area-menubar-right.menu-open {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #000;
      z-index: 1000;
    }
  
    .responsive-menu {
      display: block;
    }
  
    #mobilemenu {
      display: block;
    }
  
    .header__area-menubar-right-menu ul {
      flex-direction: column;
    }
  
    .header__area-menubar-right-box {
      margin-left: auto;
    }
  }
  

/* ======================== */
/* TestimonialsCarousel.css */
.carousel-container {
  width: 80%;
  margin: 0 auto;
  padding: 2em 0;
}

.carousel-container h2 {
  text-align: center;
  margin-bottom: 1em;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.testimonial-slide {
  padding: 1em;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-slide blockquote {
  margin: 0;
  padding: 1em;
  font-style: italic;
  font-size: 1.1em;
  color:#fff;
}

.testimonial-slide footer {
  margin-top: 0.5em;
  font-weight: bold;
  color:#000;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ddd72a;
}

/* ============================= */

body {	
  margin: 0;
  padding: 0;
  background: rgb(230,230,230);
  
  color: rgb(50,50,50);
  font-family: 'Open Sans', sans-serif;
  font-size: 112.5%;
  line-height: 1.6em;
}

/* ================ The Timeline ================ */

.timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: rgb(80,80,80);
  background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
  background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  
  z-index: 5;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(248,248,248);
  padding: 6px 10px;
  border-radius: 5px;
  
  font-weight: 600;
  text-align: left;
}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -40px;
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  background: #fff;
  border-radius: 10px;
  border: 4px solid rgb(255,80,80);
  z-index: 10;
}

.direction-r .flag:before {
  left: -40px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: rgb(248,248,248);
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(248,248,248);
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;
  
  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250,80,80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248,248,248);
}

.desc {
  margin: 1em 0.75em 0 0;
  
  font-size: 0.77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}

/* ================ Timeline Media Queries ================ */

@media screen and (max-width: 660px) {

.timeline {
 	width: 100%;
	padding: 4em 0 1em 0;
}

.timeline li {
	padding: 2em 0;
}

.direction-l,
.direction-r {
	float: none;
	width: 100%;

	text-align: center;
}

.flag-wrapper {
	text-align: center;
}

.flag {
	background: rgb(255,255,255);
	z-index: 15;
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: -30px;
	left: 50%;
	content: ' ';
	display: block;
	width: 12px;
	height: 12px;
	margin-left: -9px;
	background: #fff;
	border-radius: 10px;
	border: 4px solid rgb(255,80,80);
	z-index: 10;
}

.direction-l .flag:after,
.direction-r .flag:after {
	content: "";
	position: absolute;
	left: 50%;
	top: -8px;
	height: 0;
	width: 0;
	margin-left: -8px;
	border: solid transparent;
	border-bottom-color: rgb(255,255,255);
	border-width: 8px;
	pointer-events: none;
}

.time-wrapper {
	display: block;
	position: relative;
	margin: 4px 0 0 0;
	z-index: 14;
}

.direction-l .time-wrapper {
	float: none;
}

.direction-r .time-wrapper {
	float: none;
}

.desc {
	position: relative;
	margin: 1em 0 0 0;
	padding: 1em;
	background: rgb(245,245,245);
	-webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
	-moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
	box-shadow: 0 0 1px rgba(0,0,0,0.20);
  z-index: 15;
}

.direction-l .desc,
.direction-r .desc {
	position: relative;
	margin: 1em 1em 0 1em;
	padding: 1em;
  z-index: 15;
}

}

@media screen and (min-width: 400px ??  max-width: 660px) {
.direction-l .desc,
.direction-r .desc {
	margin: 1em 4em 0 4em;
}

}

/* ====================== faq styling ================ */
.faq-container {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.faq-container h4 {
  font-size: 1.5em;
  margin-bottom: 1em;
  color: #333;
}

.faq-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0.5em;
  overflow: hidden;
}

.faq-question {
  padding: 1em;
  cursor: pointer;
  background-color: var(--lightbrown);
  border-bottom: 1px solid var(--brown);
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.faq-question:hover {
  background-color: var(--lightbrown);
}

.faq-question.active {
  background-color:var(--lightbrown);
  color:var(--green);
}

.faq-answer {
  padding: 1em;
  background-color:#fff;
  font-size: 1em;
}


/* ====================== keyword based Pages styling ========================= */
.box1{
  /* border:2px solid red; */
  margin-bottom: 10px;
  border-bottom: 2px solid var(--green);
  padding-bottom: 10px;
}

.box1 .b1{
  width:30%;
  height:60px;
}
.box1 .b1 img{
  width:100%;
  height:100%;
  object-fit: cover;
}


.box1 .b2{
  width:60%;
}

.mybox1{
  background-color: var(--brown);
  color:#fff;
}

.keyword-img {
  height:400px;
  overflow:hidden;
}

.keyword-img img{
  width:100%;
  height:100%;
  object-fit: cover;
 
}
.keyword-img img:hover{
  transform: scale(1.1);
  transition: 1s ease-in-out;
}

.contact-section{
  margin-top:40px;
  padding: 20px;
  /* background-color: var(--brown); */
  background-color:var(--green);
  color:#fff;
}

.keywordsection h2{
  font-size: 1.5rem;
  font-weight: 600;
  color:#000;
}

.mybox{
  color:#fff;
}
.footer-logo img{
  padding-bottom: 30px;
}

/* TestimonialsCarousel.css */
.testimonial-carousel {
  width: 100%;
  margin: auto;
}

.testimonial-item {
  text-align: center;
  padding: 20px;
  background-color: #d3c7c7;

}

.testimonial-image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
  border:2px solid var(--green);
}

.testimonial-quote {
  font-style: italic;
  margin: 10px 0;
}

.testimonial-author {
  font-weight: bold;
}


/* ====================== */
/* HotelMenuSlider.css */
.hotel-menu-slider {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.slider-item {
  position: relative;
  text-align: center;
}

.slider-item img {
  width: 100%;
  height: auto;
  display: block;
}

.slider-item h3 {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.mapbox{
  height:200px;
}
.mapbox iframe{
  width:100%;
  height:200px;
  object-fit: cover;
}

/* =========================== */

.accordion-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
  margin-bottom:10px;
}

.accordion-header {
  cursor: pointer;
  padding: 15px;
  background-color: #f1f1f1;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  color:#fff;
 
}

.accordion-header.active {
  background-color: #e0e0e0;
}

.accordion-icon {
  font-size: 1.5em;
}

.accordion-content {
  padding: 0px 15px;
  font-size: 1em;
  /* line-height: 1.5; */
  background-color: #fff;
  transition: max-height 0.3s ease;
  color: #555;
  /* border:2px solid red; */
}

/* ===================== service section styling ====================== */

/* ServicesSection.css */
.services-section {
  padding:30px;
  background-color: #f9f9f9;
}

.services-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  background:  #f8f8f8;
  border-radius: 8px;
  border:2px solid var(--brown);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 4px 4px var(--brown); */
  padding: 20px;
  margin: 10px;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background-color: var(--brown);
  color:#fff;
  transition: 0.4s ease-in-out;
}

.service-card:hover .service-icon{
    color:#fff;
}

.service-card:hover h3{
  color:#fff;
}

.service-card:hover p {
  color:#fff;
}

.service-icon {
  font-size: 3rem;
  color: var(--brown);
  margin-bottom: 10px;
}

.service-card h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #333;
}

.service-card p {
  font-size: 1rem;
  color: #666;
}

.policy-section .contact-section h2{
  font-size: 2rem;
  color:#000;
}


/* ================================= */
/* TariffPlanCard.css */
.tariff-plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  background-color: #f4f4f4;
}

.tariff-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tariff-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tariff-title {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 20px;
  text-align: center;
}

.tariff-features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tariff-feature {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.tariff-feature i {
  color: #28a745;
  margin-right: 10px;
}


/* ======================= */
.gallerybox{
  height:250px;
  margin-bottom: 15px;
}

.gallerybox img{
  width:100%;
  height:100%;
  object-fit: cover;
}

/* ======================== */

.photo-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 20px;
  background-color: #f4f4f4;
}

.photo-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.photo-img {
  width: 100%;
  height: 250px;
  display: block;
  transition: opacity 0.3s ease;
}

.photo-item:hover .photo-img {
  opacity: 0.8;
}


/* ======================== whatsapp and phone button =============== */


.phone-float {
  position: fixed;
  transform: translate(108px, 0px);
  bottom: 3%;
  right: -1%;
  width: 200px;
  overflow: hidden;
  background-color: rgb(231, 61, 61);
  color: #FFF;
  border-radius: 2px 0 0 2px;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  vertical-align: middle ;
  padding: 10px 0px;

}

.phone-float a span {
  color: white;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  line-height: 16px;
  font-weight: bolder;
}

.phone-float i {
  font-size: 40px;
  color: white;
  line-height: 15px;
  padding: 20px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;


}

.phone-float:hover {
  color: #FFFFFF;
  transform: translate(0px, 0px);
}

.phone-float:hover i {
  transform: rotate(360deg);
}


.whats-float {
  position: fixed;
  transform: translate(-108px, 0px);
  bottom: 3%;
  left: 0;
  width: 180px;
  overflow: hidden;
  background-color: #48b737;
  color: #FFF;
  border-radius: 0 2px 2px 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  vertical-align: middle;
  text-align: right;
  padding: 10px 0px;
}

.whats-float a span {
  color: white;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  line-height: 16px;
  font-weight: bolder;
  left: 2px;
  transform: translateX(0);
}

.whats-float i {
  font-size: 45px;
  color: white;
  line-height: 15px;
  padding: 20px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align: center;
  right: 10px;
}

.whats-float:hover {
  color: #FFFFFF;
  transform: translate(0px, 0px);
}

.whats-float:hover i {
  transform: rotate(360deg);
}

/* ============================= */

.nav-link{
  color:black!important;
  font-weight: bold;
}

.nav-item{
  display: flex;
  align-items: center;
}

.myImage{
  overflow: hidden;
}
.myImage img:hover{
   transform:scale(1.4);
   transition: 1s ease-in-out;
}



/* ========================= About page css ========================== */

.visionbox{
  margin-bottom: 20px;
  border:2px solid var(--green);
}

.textgreen{
  color:var(--green);
}

/* ==============================  */

.contact__area-info-item{
  box-shadow: 0px 3px 4px var(--green);
  padding: 20px;
}


.contact-head{
   border-bottom: 2px solid var(--green);
}

.menubox{
  margin-bottom: 40px;
}

/* ==================== */


@media(max-width:400px){
  .myslider{
    height:186px;
  }

  .myslider img{
    width:100%;
    height:100%;
    
  }
}


.faq-container {
  width: 100%;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; 
}

.accordion-item {
  border: 2px solid var(--brown)!important;
  border-radius: 5px;
  padding: 10px;
}

.accordion-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  background: var(--green);
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-icon {
  margin-left: 10px;
}


.text{
  color:var(--green);
}


.accordion-header.active{
  background:var(--green)!important;
}


.Testimonial-section{
  background-image: linear-gradient(rgba(0, 0, 0, 0.247),rgba(0, 0, 0, 0.26)),url("../public/Hotel-Images/page-banner-7.jpg");
}
.clientText{
  font-size:3rem;
  padding-bottom: 20px;
  line-height: 45px;
}


@media (max-width: 768px) {
  .faq-grid {
    grid-template-columns: 1fr; /* One column on mobile */
  }
}

/* ======================== */
.hotel-section {
  overflow: hidden;
  width: 100%;
  padding: 20px;
  /* background-color: #f0f0f0; */
 
}

.hotel-cards-container {
  display: flex;
  white-space: nowrap;
  animation: scroll 30s linear infinite;
}

.hotel-card {
  display: inline-block;
  width: 250px;
  margin-right: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  border:2px solid var(--lightbrown);
}

.hotel-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}


.hotel-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  max-height: 80px; /* Limit description height to prevent overflow */
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis if the text is too long */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.mybtn{
  padding:0.8rem 1.2rem;
  border-radius: 20px;
  background-color: var(--green);
  color:#fff;
}
.text-blog{
  color:var(--green);
  font-size: 1.8rem;
}

.section{
   padding:3rem 0rem;
}

.myfoot a,
.myfoot h4,
.myfoot p{
   color:var(--lightbrown); 
}

.mymenulist{
  border:2px solid var(--green);
}

.mymenulist ul li{
  border:2px solid var(--brown)
}


@media(max-width:768px){
  .navbar-nav li{
    border-bottom: 1px solid var(--green);
    padding: 0px 10px;
    width:100%;
  }
  .navbar-nav{
    width:100%;
    margin-left:0px;
  }
}

.email-container {
  padding: 10px;            /* Add some padding */
  word-break: break-word;    /* Breaks long words like emails */
  max-width: 100%;           /* Ensure the container doesn't overflow */
  box-sizing: border-box;    /* Include padding in width calculation */
}

.email-container a {
  text-decoration: none;     /* Optional: Remove underline */
  color: #333;               /* Optional: Change color */
}

@media (max-width: 768px) {
  .email-container p {
    font-size: 1rem;        /* Adjust font-size for mobile */
  }
}

.header-box1{
  padding: 8px;
  background-color: var(--green);
}
.header-box2{
display: flex;
flex-direction: end;
} 


.mycontainer{
  max-width:1200px;
  width:990px;
 
} 

.navbar-nav{
  margin-left: 40px;
}

@media(max-width:768px){
  .navbar-nav{
    margin-left: 0px;
  }

  .nav-item{
    display: block;
  }
  
}

.logo{
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color:#fff;
}

.logo a{
  line-height: 20px;
  letter-spacing: 0.4px;
} 

.mytext{
  font-size: 1rem;
}

.mytext1{
  font-size: 0.8rem;
}

@media(max-width:768px){
  .footer-logo img{
    margin-top: 20px;
  }
}

